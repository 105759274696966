import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { FaChevronRight } from "react-icons/fa6";
import SideBar from "../Components/Bars/SideBar";

const Terms = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      fullName: "",
      bankName: "",
    },
    // validationSchema: signupSchema,
    onSubmit: (values, { setSubmitting }) => {
      const data = {
        ...values,
      };

      // dispatch(register({ navigate, data, setSubmitting }));
    },
  });

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Terms and Conditions"}
          description={""}
          name={"Chatta Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          {/* <SideBar /> */}
          <div className="flex-1 justify-center items-end max-w-[100vw] flex overflow-x-auto pt-4 pb-6 px-5 md:px-9">
            <div className="w-full sm:w-[930px] h-[80vh] overflow-y-scroll">
              <h4 className="font-[600] text-[40px] mb-4 sm:text-[72px] leading-tight text-[#000000]">
                Privacy <br />
                Policy
              </h4>
              <div className="flex flex-col gap-3 sm:gap-4">
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  Privacy Policy for Chatta Finance
                </h4>

                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  Effective Date: 15/06/2024 Chatta Finance is committed to
                  protecting the privacy and security of your personal
                  information. This Privacy Policy outlines the types of
                  personal information we collect, how we use and protect that
                  information, and your rights regarding your personal data.  
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  Definitions: “we/us/our” or “Chatta Finance” means Chatta Link
                  Limited “you” or “user”  means a visitor to our Website,
                  acting individually or on behalf of a third-party or as a
                  representative of an existing or potential customer;
                  “third-party” means someone who is not you or us or a part of
                  Chatta Link Limited;
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  1. Who is collecting your personal data? Chatta Link Limited
                  and its affiliated entities is the data controller of your
                  personal data. This means that each of these entities
                  determines for what purposes and through which means your
                  personal data is processed.  
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  2. Information We Collect - Personal Information: We may
                  collect personal information such as your name, address, email
                  address, phone number, date of birth, social security number,
                  and financial information when you use our services. - Usage
                  Information: We may collect information about your
                  interactions with our website, mobile application, or other
                  services, including IP address, browser type, browser
                  language, domain, device identifiers, pages visited, and time
                  spent on each page. This information is usually collected
                  through the use of cookies. - Cookies and Tracking
                  Technologies: Cookies are small files that websites send to a
                  computer or wireless device to uniquely identify a browser or
                  mobile device or to store information in a browser setting. We
                  may use cookies and similar tracking technologies to enhance
                  your experience, analyze trends, and administer our services.
                  For more information about the cookies we use and how to
                  manage your cookies settings please visit our Cookie Policy.  
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                   3. How We Use Your Information - Provide and Improve
                  Services: Analyzing conversations, phone calls, or other type
                  of communication that takes place between you and our team. We
                  use this information to deliver and improve our products and
                  services, personalize your experience, and respond to your
                  inquiries. - Security and Fraud Prevention: We use your
                  information to protect against security breaches, fraud, and
                  other illegal activities. - Marketing and Communications: With
                  your consent, we may send you promotional materials,
                  newsletters, and other communications about our products and
                  services.  
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  4. Information Sharing We may share your personal information
                  with companies, consultants or third parties who are engaged
                  to perform services for, or on behalf of Chatta Link Limited
                  including for example, software services, security, IT and
                  technical service providers, financial institutions,
                  regulatory authorities, archiving and data storage services
                  suppliers; These entities are carefully selected and regularly
                  reviewed by us to ensure that your personal information is
                  protected. These entities may only use the data for the
                  purposes specified by us and are also contractually obligated
                  to process your personal information in accordance with this
                  privacy statement and applicable data protection legislation.
                  Consultancy services providers and any other similar services
                  providers (e.g., lawyers, auditors, accountants, on various
                  matters); A third party or body where such disclosure is
                  required to satisfy any applicable law, or other legal,
                  contractual or regulatory requirement; We do not sell or rent
                  your personal information to third parties for marketing
                  purposes without your explicit consent.  
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  5. Data Retention We retain your personal information for as
                  long as necessary to fulfill the purposes outlined in this
                  Privacy Policy unless a longer retention period is required or
                  permitted by law.  
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  6. Your Rights Depending on and subject to applicable laws,
                  you have certain rights regarding the personal data that we
                  hold about you.
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  Right to correct information: you have the right to have the
                  information we hold about you corrected if it is not accurate.
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  Right to access: you have the right to be provided with access
                  to data held about you by us and type of processing (purpose,
                  categories of personal data processed, recipients / third
                  parties having access to your data), retention period. You may
                  also ask a free-of-charge copy of your personal data we
                  process. Right to object: you have the right to object to the
                  processing of your personal information where we rely on our
                  legitimate ground to do so. Your objection will be balanced
                  against our specific legitimate ground for processing.
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  Right to deletion: in certain circumstances you have the right
                  to request that we erase the personal information we hold
                  about you.
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  Right to restrict the use of your information: if you feel the
                  personal information we hold about you is inaccurate or
                  believe we shouldn’t be processing your personal information,
                  you may have the right to ask us to restrict the processing of
                  that information.
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  Withdrawal of consent: you may withdraw any consent to
                  processing and prevent further processing if there is no other
                  ground than consent under which Chatta Finance can process
                  your personal data.
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  Right to lodge a complaint with the competent data protection
                  supervisory authority about the way we handle or process your
                  personal data.
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  These rights can be exercised by contacting us at
                  privayconcerns@Chattafinance.com. If we don’t take action
                  based on any of the above requests, we will inform you of our
                  reasons for this.  
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  If you have any questions or concerns about our Privacy Policy
                  or practices, please contact us at
                  privayconcerns@Chattafinance.com.
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                    7. Security Measures Within Chatta Link Limited the security
                  and confidentiality of your personal information are very
                  important, therefore we employ industry-standard security
                  measures to protect your personal information from
                  unauthorized access, disclosure, alteration, or destruction.  
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  8. Changes to This Privacy Policy We reserve the right to
                  update or modify this Privacy Policy at any time. We will
                  notify you of any changes by posting the revised policy to the
                  extent required by law on our website or through other
                  communication channels.    
                </h4>
                <h4 className="font-[400] mt-1 text-[16px] sm:text-[18px] text-[#000000]">
                  9. Consent By using our services, you consent to the
                  collection, use, and disclosure of your personal information
                  as described in this Privacy Policy.    
                </h4>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Terms;
