import React, { useEffect, useMemo } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import SideBar from "../Components/Bars/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CgCheck } from "react-icons/cg";
import { GrFormClose } from "react-icons/gr";
import { getPayment } from "../store/actions/payments/getPayment";
import Loader from "../Components/Utils/Loader";
import { formatAmount } from "../utils/formatAmount";
import { timeformatter } from "../utils/dateformatter";

const TrackingDetails = () => {
  const { id } = useParams();

  const { pending, dataDetails } = useSelector((state) => state?.tracking);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const steps = [
    {
      done: "Verified by Chatta Finance",
      pending: "Verified by Chatta Finance",
      completedAt: "Today, 14:56 pm",
    },
    {
      done: "Sent to recipient’s bank",
      pending: "Sent to recipient’s bank",
      completedAt: "Today, 14:57 pm",
    },
    {
      done: "Received by recipient’s bank",
      pending: "Pending by recipient’s bank",
      completedAt: "Today, 19:22 pm",
    },
  ];

  useEffect(() => {
    dispatch(getPayment(id));
  }, [id]);

  const step = useMemo(() => {
    return dataDetails?.status === "success"
      ? 2
      : dataDetails?.status === "pending"
      ? 1
      : 0;
  }, [dataDetails]);
  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Track Payments"}
          description={""}
          name={"Chatta Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <SideBar />
          <div className="flex-1 mb-14 max-w-[100vw] overflow-x-auto pt-6 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <div className="flex items-center justify-between">
              <h4 className="font-[600] text-lg mb-1">Track Payment</h4>
            </div>

            {pending ? (
              <Loader />
            ) : (
              <div className="mt-5 ">
                <div className=" max-w-[95%] mx:auto sm:max-w-[577px] mb-6  ">
                  <div className="flex max-w-full mb-6  flex-wrap  justify-between">
                    <h4 className="font-[400] text-[16px] md:text-[18px] text-[#000]  ">
                      Recipient Name:
                    </h4>
                    <h4 className="font-[600] text-[16px] md:text-[18px] text-[#000]">
                      {dataDetails?.recipient}
                    </h4>
                  </div>
                  <div className="flex max-w-full mb-6  flex-wrap  justify-between">
                    <h4 className="font-[400] text-[16px] md:text-[18px] text-[#000]  ">
                      Amount Sent:
                    </h4>
                    <h4 className="font-[600] text-[16px] md:text-[18px] text-[#000]">
                      {formatAmount(dataDetails?.amountSent)}
                    </h4>
                  </div>
                  <div className="flex max-w-full mb-6  flex-wrap  justify-between">
                    <h4 className="font-[400] text-[16px] md:text-[18px] text-[#000]  ">
                      Amount received:
                    </h4>
                    <h4 className="font-[600] text-[16px] md:text-[18px] text-[#000]">
                      {formatAmount(dataDetails?.amountReceived)}
                    </h4>
                  </div>
                  <div className="flex max-w-full mb-6  flex-wrap  justify-between">
                    <h4 className="font-[400] text-[16px] md:text-[18px] text-[#000]  ">
                      {timeformatter(
                        dataDetails?.transaction_date,
                        "formal",
                        true
                      )}
                    </h4>
                  </div>
                </div>

                <div className="flex max-w-[95%] flex-col min-h-[305px] bg-[#fff] mx:auto sm:max-w-[577px] mb-6 py-7 px-5 rounded-[17px] flex-wrap justify-center">
                  {steps?.map((item, index) => {
                    return (
                      <div key={index} className="flex gap-2 ">
                        <div className="flex flex-col  justify-center items-center">
                          <div
                            style={{
                              backgroundColor:
                                index <= step ? "#00D68F" : "#E59907",
                            }}
                            className="rounded-full h-[20px]  w-[20px]  flex justify-center items-center"
                          >
                            {index <= step ? (
                              <CgCheck color={"#FFFFFF"} size={"20px"} />
                            ) : (
                              <GrFormClose color={"#FFFFFF"} size={"20px"} />
                            )}
                          </div>

                          <div
                            style={{
                              borderColor: index !== 2 ? "#000000" : null,
                              borderWidth: index !== 2 ? "0.8px" : null,
                              borderStyle: index !== 2 ? "dashed" : null,
                              height: index !== 2 ? "105px" : null,
                            }}
                            className="  w-[0px]  "
                          ></div>
                        </div>
                        <div className=" flex-col  flex gap-[2px]">
                          <h3 className="text-[#000] font-[400] text-[14px]  ">
                            {index <= step ? item?.done : item?.pending}
                          </h3>
                          {index <= step ? (
                            <h3 className="text-[#999] font-[400] text-[14px]  ">
                              {item?.completedAt}
                            </h3>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TrackingDetails;
