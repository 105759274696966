import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import SideBar from "../Components/Bars/SideBar";
import IncomeAndExpenditure from "../Components/Charts/IncomeAndExpenditure";
import CustomPiechart from "../Components/Charts/CustomPiechart";
import Button from "../Components/Inputs/Button";
import { FaChevronDown } from "react-icons/fa6";
import ButtonDropDown from "../Components/Inputs/ButtonDropDown";
import { CgChevronRight } from "react-icons/cg";
import { TbEyeOff } from "react-icons/tb";
import { TbEye } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { getBanks } from "../store/actions/banks/getBanks";
import { getProfile } from "../store/actions/profile/getProfile";
import { getWallet } from "../store/actions/wallet/getWallet";
import { useNavigate } from "react-router-dom";
import { getTransactionDistribution } from "../store/actions/dasboard/getTransactionDistribution copy";
import { getTransactionsCountries } from "../store/actions/dasboard/getTransactionsCountries";

const Dashboard = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const [currentWallet, setCurrentWallet] = useState(0);

  const { wallet, transactionDistribution, transactionsCountries } =
    useSelector((state) => state?.dashboard);

  const walets = [
    {
      name: "Naira Wallet",
      amount: wallet?.nairaBalance || 0,
      currency: "₦ ",
      curr: "NGN",
    },
    {
      name: "Pound Wallet",
      amount: wallet?.poundBalance || 0,
      currency: "£ ",
      curr: "GBP",
    },
    {
      name: "Dollar Wallet",
      amount: wallet?.dollarBalance || 0,
      currency: "$ ",
      curr: "USD",
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getBanks());
    dispatch(getWallet());
    dispatch(getTransactionDistribution());
    dispatch(getTransactionsCountries());
  }, []);

  const [freq, setFreq] = useState("daily");

  return (
    <div className="font-mons">
      <SEO
        title={"Chatta Dashbaord"}
        description={""}
        name={"Chatta Finance"}
        type={"article"}
      />
      <Header />
      <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
        <SideBar />
        <div className="flex-1 mb-14  max-w-[100vw] overflow-x-auto pt-4 pb-6 px-2 sm:px-5 bg-[#F1F1F1]">
          <div className="flex flex-col sm:flex-row w-full sm:min-w-[1043px] gap-5 mb-2">
            <div className="bg-[#FFF] border-[#D1D1D1] border-[0.8px] rounded-[20px] px-6 py-5 mt-4 sm:min-w-[410px] h-[333px] flex flex-col">
              <h4 className="font-[600] text-lg">Wallet Balance Summary</h4>
              <div className="flex items-center gap-5">
                <div className="w-[300px] bg-gradient-to-br from-[#192851] p-4 to-[#93316B]  h-[170px]  rounded-[12px] mt-5 mb-4">
                  <div className="w-full flex flex-col justify-between h-full  bg-[url('./Assets/Images/wallet.png')] bg-cover bg-no-repeat rounded-[12px] ">
                    <div>
                      <h4 className="font-[500]  text-sm mb-1 text-[#FFF] ">
                        Current Balance
                      </h4>
                      <div className="flex  gap-3 justify-between items-center">
                        <h4 className="font-[700] text-lg text-[#FFF] ">
                          {walets[currentWallet].currency}
                          {show
                            ? walets[currentWallet].amount.toFixed(2)
                            : " * * * * * *"}
                        </h4>
                        {show ? (
                          <TbEyeOff
                            onClick={() => setShow(false)}
                            color="#FFFFFF"
                            size={"25px"}
                            className="cursor-pointer"
                          />
                        ) : (
                          <TbEye
                            onClick={() => setShow(true)}
                            color="#FFFFFF"
                            className="cursor-pointer"
                            size={"25px"}
                          />
                        )}
                      </div>
                    </div>
                    <h4 className="font-[500]  text-xs text-[#FFF] ">
                      {walets[currentWallet].name}
                    </h4>
                  </div>
                </div>
                <CgChevronRight
                  onClick={() => {
                    setCurrentWallet((prev) => (prev >= 2 ? 0 : prev + 1));
                  }}
                  className="cursor-pointer"
                  color="#000"
                  size={"30px"}
                />
              </div>
              <Button
                bg={"#192851"}
                title={"Fund Wallet"}
                textStyles="!font-[500] !text-[12px]"
                mainClasses="!h-[33px] !w-[111px] !rounded-[6px]"
                onClick={() => {
                  navigate(
                    `/fund-wallet?currency=${walets[currentWallet].curr}`
                  );
                }}
              />
            </div>
            <div className="bg-[#FFF] border-[#D1D1D1] overflow-hidden border-[0.8px] rounded-[20px] px-4 py-4 mt-4 flex-1 max:w-[762px] h-[333px]">
              <div className="flex justify-between items-center mb-5 flex-wrap">
                <div>
                  <h4 className="font-[600] text-lg">
                    Top transactions by countries
                  </h4>

                  <div className="flex gap-1 cursor-pointer mt-3 items-center">
                    <h4 className="font-[500] underline text-sm text-[#395AB5] ">
                      Expenditure
                    </h4>
                    <FaChevronDown size={"8px"} color="#395AB5" />
                  </div>
                </div>
                <ButtonDropDown
                  title={"May 2024"}
                  textStyles="!font-[500] !text-[13px] !text-[#000000]"
                  mainClasses="!h-[33px] !w-[111px] !bg-[#D8E2FF] !rounded-[6px]"
                  dropDownClasses="!w-[111px] !bg-[#D8E2FF] !rounded-[6px]"
                  onClick={() => {}}
                />
              </div>
              <div className="h-[75%] ">
                <div className="flex border-b-[#D1D1D1]  border-b-[0.5px] py-3">
                  <h4 className="font-[500] text-sm text-[#707070] w-[120px] ">
                    No.
                  </h4>
                  <h4 className="font-[500] text-sm text-[#707070] w-[220px]">
                    Country
                  </h4>
                  <h4 className="font-[500] text-sm text-[#707070] w-[190px] ">
                    Transaction Qty
                  </h4>
                  <h4 className="font-[500] text-sm text-[#707070] w-[70px] ">
                    Amount
                  </h4>
                </div>
                <div className="overflow-y-auto h-[80%] ">
                  <div className="flex border-b-[#D1D1D1]  border-b-[0.5px] py-3">
                    <h4 className="font-[500] text-sm text-[#000000] w-[120px] ">
                      1.
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[220px] ">
                      Nigeria
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[190px]">
                      10 transactions
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[70px] ">
                      15,000
                    </h4>
                  </div>
                  <div className="flex border-b-[#D1D1D1]  border-b-[0.5px] py-3">
                    <h4 className="font-[500] text-sm text-[#000000] w-[120px] ">
                      1.
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[220px] ">
                      Nigeria
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[190px]">
                      10 transactions
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[70px] ">
                      15,000
                    </h4>
                  </div>
                  <div className="flex border-b-[#D1D1D1]  border-b-[0.5px] py-3">
                    <h4 className="font-[500] text-sm text-[#000000] w-[120px] ">
                      1.
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[220px] ">
                      Nigeria
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[190px]">
                      10 transactions
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[70px] ">
                      15,000
                    </h4>
                  </div>
                  <div className="flex border-b-[#D1D1D1]  border-b-[0.5px] py-3">
                    <h4 className="font-[500] text-sm text-[#000000] w-[120px] ">
                      1.
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[220px] ">
                      Nigeria
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[190px]">
                      10 transactions
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[70px] ">
                      15,000
                    </h4>
                  </div>
                  <div className="flex border-b-[#D1D1D1]  border-b-[0.5px] py-3">
                    <h4 className="font-[500] text-sm text-[#000000] w-[120px] ">
                      1.
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[220px] ">
                      Nigeria
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[190px]">
                      10 transactions
                    </h4>
                    <h4 className="font-[500] text-sm text-[#000000] w-[70px] ">
                      15,000
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 ml-2 ">
            <h4 className="font-[600] text-xl ">Analytics</h4>
          </div>

          <div className="flex flex-col sm:flex-row w-full sm:min-w-[1043px] justify-between sm:items-center">
            <ButtonDropDown
              title={"May 2024"}
              textStyles="!font-[500] !text-[13px] !text-[#000000]"
              mainClasses="!h-[33px] !w-[111px] mt-2 !bg-[#FFFFFF] !rounded-[6px]"
              dropDownClasses="!w-[111px] !bg-[#FFFFFF] !rounded-[6px]"
              onClick={() => {}}
            />

            <div className="flex gap-3">
              <Button
                bg={"#192851"}
                title={"Daily"}
                textStyles={`!font-[500] !text-[12px]  ${
                  freq === "daily" ? "" : "!text-[#000]"
                }`}
                mainClasses={`!h-[33px] !w-[111px] !rounded-[6px] ${
                  freq === "daily" ? "" : "!bg-[#FFF]"
                }`}
                onClick={() => {
                  setFreq("daily");
                }}
              />
              <Button
                bg={"#192851"}
                title={"Weekly"}
                textStyles={`!font-[500] !text-[12px]  ${
                  freq === "weekly" ? "" : "!text-[#000]"
                }`}
                mainClasses={`!h-[33px] !w-[111px] !rounded-[6px] ${
                  freq === "weekly" ? "" : "!bg-[#FFF]"
                }`}
                onClick={() => {
                  setFreq("weekly");
                }}
              />
              <Button
                bg={"#192851"}
                title={"Monthly"}
                textStyles={`!font-[500] !text-[12px]  ${
                  freq === "monthly" ? "" : "!text-[#000]"
                }`}
                mainClasses={`!h-[33px] !w-[111px] !rounded-[6px] ${
                  freq === "monthly" ? "" : "!bg-[#FFF]"
                }
                 `}
                onClick={() => {
                  setFreq("monthly");
                }}
              />
            </div>
          </div>

          <div className="flex flex-col sm:flex-row sm:min-w-[1043px] w-full gap-5">
            <div className="bg-[#FFF] border-[#D1D1D1] border-[0.8px] rounded-[20px] px-4 py-4 mt-2 flex-1 max:w-[762px] h-[319px]">
              <div className="flex justify-between items-center mb-5">
                <h4 className="font-[600] text-lg">Income vs Expenditure</h4>

                <div className="flex gap-3 items-center">
                  <div className="flex gap-2 items-center">
                    <div className="w-[11px] h-[11px] rounded-full bg-[#192851]"></div>
                    <h4 className="font-[400] text-xs text-[#707070] ">
                      Income
                    </h4>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="w-[11px] h-[11px] rounded-full bg-[#BECCF1]"></div>
                    <h4 className="font-[400] text-xs text-[#707070] ">
                      Expenditure
                    </h4>
                  </div>
                </div>
              </div>

              <IncomeAndExpenditure />
            </div>
            <div className="bg-[#FFF] border-[#D1D1D1] border-[0.8px] rounded-[20px] px-4 py-4 mt-2 min-w-[332px] h-[319px] flex flex-col items-center justify-between">
              <h4 className="font-[600] text-lg">Transaction Distribution</h4>

              <div className="w-[200px] h-[200px]">
                <CustomPiechart
                  width="100%"
                  height="100%"
                  pieData={[
                    { name: "", value: 7 },
                    { name: "", value: 40 },
                    { name: "", value: 100 },
                    { name: "", value: 6 },
                  ]}
                  // outerradius="40"
                  innerradius="38"
                />
              </div>
              <div className="flex gap-5">
                <div>
                  <div className="flex gap-2 items-center">
                    <div className="w-[11px] h-[11px] rounded-full bg-[#85B6FF]"></div>
                    <h4 className="font-[400] text-xs text-[#707070] ">
                      Nigeria
                    </h4>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="w-[11px] h-[11px] rounded-full bg-[#4ECB71]"></div>
                    <h4 className="font-[400] text-xs text-[#707070] ">
                      United Kingdom
                    </h4>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="w-[11px] h-[11px] rounded-full bg-[#FFD233]"></div>
                    <h4 className="font-[400] text-xs text-[#707070] ">
                      Senegal
                    </h4>
                  </div>
                </div>
                <div>
                  <div className="flex gap-2 items-center">
                    <div className="w-[11px] h-[11px] rounded-full bg-[#FF9A62]"></div>
                    <h4 className="font-[400] text-xs text-[#707070] ">
                      Cameroon
                    </h4>
                  </div>

                  <div className="flex gap-2 items-center">
                    <div className="w-[11px] h-[11px] rounded-full bg-[#D99BFF]"></div>
                    <h4 className="font-[400] text-xs text-[#707070] ">
                      Ghana
                    </h4>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="w-[11px] h-[11px] rounded-full bg-[#E4A951]"></div>
                    <h4 className="font-[400] text-xs text-[#707070] ">
                      Greece
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
