import React from "react";

// import { useNavigate } from "react-router-dom";

const Footer = () => {
  // const navigate = useNavigate();

  const routes = [
    { name: "Home", route: "/" },
    { name: "Transfer", route: "#" },
    { name: "Refer and Earn", route: "/refer" },
    { name: "Analytics", route: "#" },
    { name: "Settings", route: "/settings" },
    { name: "Support", route: "#" },
  ];

  const extraRoutes = [
    { name: "About", route: "/about-us" },
    { name: "Terms and Conditions", route: "#" },
    { name: "Privacy Policy", route: "/privacy-policy" },
    { name: "Cookie Policy", route: "#" },
    { name: "Contact us", route: "#" },
  ];

  return (
    <section className=" lg:h-[408px] font-mons bg-[#1A1A1A] ">
      <div className="container w-[97%] sm:w-[90%] gap-4 flex flex-col sm:flex-row justify-between mx-auto px-3 py-5 md:px-10 md:py-12">
        <div className="flex-1 md:flex-[4] ">
          <div className=" flex flex-col gap-2 w-[260px]">
            <div className=" flex flex-col gap-2  ">
              <a href="/">
                <div
                  onClick={() => {
                    // navigate("/");
                  }}
                  className="cursor-pointer flex gap-2 items-center"
                >
                  <img
                    src={require("../../Assets/Logo/logo.png")}
                    className="w-[62px] md:w-[70px] object-contain h-[40px] md:h-[48px]"
                  />
                </div>
              </a>
              <div>
                <h4 className="font-[400] text-[12px] sm:text-[15px] w-[75%] lg:w-[70%]  text-[rgba(255,255,255,1)]">
                  Kirkstall Road, Leeds, United Kingdom.
                  {/* 9 Burley Wood Court 462 Kirkstall Road LEEDS, N/A LS4 2QD
                  United Kingdom */}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 md:flex-[3] flex justify-between">
          <ul className="flex flex-col ">
            {routes.map((route, index) => (
              <li key={`${route}${index}`}>
                <a href={route.route}>
                  <h4
                    onClick={() => {}}
                    className="font-[400] hover:bg-[rgba(25,40,81,1)]  text-[12px] sm:text-[15px] py-[3px] pl-2 pr-3 rounded transition-all duration-500 ease-in-out  cursor-pointer text-[rgba(255,255,255,1)]"
                  >
                    {route.name}
                  </h4>
                </a>
              </li>
            ))}
          </ul>
          <ul className="flex flex-col ">
            {extraRoutes.map((route, index) => (
              <li key={`${route}${index}`}>
                <a href={route.route}>
                  <h4 className="font-[400] hover:bg-[rgba(25,40,81,1)]  text-[12px] sm:text-[15px] py-[3px] pl-2 pr-3 rounded transition-all duration-500 ease-in-out  cursor-pointer text-[rgba(255,255,255,1)]">
                    {route.name}
                  </h4>
                </a>
              </li>
            ))}
            <div className="flex gap-2 mt-1 pt-1 pl-2 pr-3">
              <a href={"#"}>
                <img
                  className="w-[32px] h-[32px] object-contain cursor-pointer"
                  src={require("../../Assets/Icons/facebook.png")}
                />
              </a>

              <a href={"#"}>
                <img
                  className="w-[32px] h-[32px] object-contain cursor-pointer"
                  src={require("../../Assets/Icons/twitter.png")}
                />
              </a>
              <a href={"#"}>
                <img
                  className="w-[32px] h-[32px] object-contain cursor-pointer"
                  src={require("../../Assets/Icons/linkedin.png")}
                />
              </a>
            </div>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Footer;
