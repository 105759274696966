import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import SEO from "../Components/Utils/Seo";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { FaChevronRight } from "react-icons/fa6";
import FilePicker from "../Components/Inputs/FilePicker";
import { formHandler } from "../utils/formhandler";
import { completeKYC } from "../store/actions/profile/completeKYC";

const KYC = () => {
  const [isubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {
      proofOfAddress: null,
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (form?.values?.["proofOfAddress-name"]) {
      setSubmitting(true);
      const formData = new FormData();
      formData.append("proofOfAddress", form.values.proofOfAddress);

      dispatch(
        completeKYC({
          cb: () => {
            // resetForm();
            setSubmitting(false);
            // navigate("/payments");
            // setShowSuccess(true);
          },
          payload: formData,
          failed: () => {
            setSubmitting(false);
          },
        })
      );
    }
  }, [form?.values]);

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Complete KYC"}
          description={""}
          name={"Chatta Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <div className="flex-1  max-w-[100vw] overflow-x-auto pt-4 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <h4
              onClick={() => {
                navigate(-1);
              }}
              className="cursor-pointer text-[21px] sm:text-[25px] font-[600] w-[646px] max-w-[100%] mt-6 mb-3 mx-auto text-[#000]"
            >
              Settings / KYC Verification
            </h4>
            <div className="w-[646px] max-w-[100%] px-5 py-5 sm:px-8 mx-auto bg-[#FFFFFF]">
              <div
                onClick={() => {
                  navigate("/settings/kyc/driverslicense");
                }}
                className="flex items-center py-5 sm:py-6 px-2 cursor-pointer border-b-[0.7px] border-b-[#707070]  justify-between"
              >
                <div className="flex items-center gap-6  sm:gap-8">
                  <h4 className="text-[16px] sm:text-[18px] font-[500] text-[#000]">
                    Driver’s license
                  </h4>
                </div>
                <FaChevronRight color="#707070" size={"24px"} />
              </div>
              <div
                onClick={() => {
                  navigate("/settings/kyc/passport");
                }}
                className="flex items-center py-5 sm:py-6 px-2 cursor-pointer border-b-[0.7px] border-b-[#707070]  justify-between"
              >
                <div className="flex items-center gap-6  sm:gap-8">
                  <h4 className="text-[16px] sm:text-[18px] font-[500] text-[#000]">
                    Passport
                  </h4>
                </div>
                <FaChevronRight color="#707070" size={"24px"} />
              </div>
              <div
                onClick={() => {
                  navigate("/settings/kyc/biometricresidentialpassport");
                }}
                className="flex items-center py-5 sm:py-6 px-2 cursor-pointer border-b-[0.7px] border-b-[#707070]  justify-between"
              >
                <div className="flex items-center gap-6  sm:gap-8">
                  <h4 className="text-[16px] sm:text-[18px] font-[500] text-[#000]">
                    Biometric residential passport
                  </h4>
                </div>
                <FaChevronRight color="#707070" size={"24px"} />
              </div>
              <div className="py-2 mt-5">
                <h4 className="text-[16px] sm:text-[18px] font-[500] text-[#000]">
                  Proof of address
                </h4>
                <h4 className="text-[12px] sm:text-[14px] font-[400] text-[#666666]">
                  Upload any of these to verify your address
                </h4>
                <div className="mt-4">
                  <h4 className="text-[14px] sm:text-[16px] leading-tight font-[400] text-[##000000]">
                    Utility bill, phone bill, rent bill <br />
                    or mortgage statement
                  </h4>
                  <FilePicker
                    type={2}
                    loading={isubmitting}
                    id="proofOfAddress"
                    {...formHandler("proofOfAddress", form)}
                    form={form}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KYC;
