import React from "react";
import TextInput from "../Inputs/TextInput";
import Button from "../Inputs/Button";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { signupSchema } from "../../utils/validationSchemas";
import { register } from "../../store/actions/onboarding/register";
import { useNavigate } from "react-router-dom";
import { formHandler } from "../../utils/formhandler";

const SignupMain = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      password: "",
      confirmPassword: "",
      referralCode: "",
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: signupSchema,
    onSubmit: (values, { setSubmitting }) => {
      const data = {
        ...values,
      };

      dispatch(register({ navigate, data, setSubmitting }));
    },
  });

  return (
    <div className="w-[90%] md:w-[577px] justify-between rounded-[28px] bg-[rgba(255,255,255,0.9)] flex-col flex py-4 3xl:py-8 px-6 md:h-[698px] max-h-[85%]   items-center">
      <div className="flex-col mt-0 3xl:mt-6 mb-5 flex items-center  h-full w-full">
        <h3 className="text-[#000] mt-4 mb-1 font-[700] text-[24px] text-center">
          Sign Up
        </h3>
        <div className="flex-1 flex flex-col items-center overflow-y-auto">
          <TextInput
            title={"Full Name"}
            {...formHandler("fullName", form)}
            type={"text"}
            placeholder={"Enter full name"}
            mainClasses={"mb-[12px] mt-6 w-full md:w-[367px]"}
          />
          <TextInput
            title={"Email address"}
            {...formHandler("email", form)}
            type={"email"}
            placeholder={"Enter email"}
            mainClasses={"mb-[12px] w-full md:w-[367px]"}
          />
          <TextInput
            title={"Referral Code"}
            {...formHandler("referralCode", form)}
            type={"text"}
            placeholder={"Ente Referral Code"}
            mainClasses={"mb-[12px] mt-6 w-full md:w-[367px]"}
          />
          <TextInput
            title={"Create a Password"}
            {...formHandler("password", form)}
            type={"password"}
            placeholder={"Enter password"}
            mainClasses={"mb-[12px] w-full md:w-[367px]"}
          />
          <TextInput
            title={"Confirm Password"}
            {...formHandler("confirmPassword", form)}
            type={"password"}
            placeholder={"Enter confirm password"}
            mainClasses={"mb-[12px] w-full md:w-[367px]"}
          />
          <div className="mb-8 w-full md:w-[363px] mt-[4px] flex justify-between items-center ">
            <h4 className="text-[#333] font-[400] text-[10px] ">
              By submitting this form, you accept Chatta FINANCE{" "}
              <span className="text-[#000] font-[600] cursor-pointer">
                Terms and Conditions
              </span>{" "}
              and{" "}
              <span
                onClick={() => navigate("/privacy-policy")}
                className="text-[#000] font-[600] cursor-pointer"
              >
                Privacy Policy
              </span>
              .
            </h4>
          </div>
          <Button
            bg={"#192851"}
            title={"Next"}
            onClick={form.handleSubmit}
            loading={form.isSubmitting}
            disabled={!form.dirty || form.isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default SignupMain;
