import React, { useEffect, useMemo, useState } from "react";
import CurrencyComponent from "../Inputs/CurrencyComponent";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getTransferRates } from "../../store/actions/transactions/getTransferRates";

const ExchangeRate = ({ styles, type, inputStyles, form, oldState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rates } = useSelector((state) => state.transactions);

  const [currencyName1, setCurrencyName1] = useState(
    oldState?.currency || "GBP"
  );
  const [currencyName2, setCurrencyName2] = useState(
    oldState?.recipientCurrency || "NGN"
  );
  const [currency1, setCurrency1] = useState(1);
  const [currency2, setCurrency2] = useState(
    rates?.find((rate) => rate?.currency === oldState?.currency || "GBP")
      ?.quotes[
      `${oldState?.currency || "GBP"}${oldState?.recipientCurrency || "NGN"}`
    ]
  );

  const rate = useMemo(
    () =>
      rates?.find((rate) => rate?.currency === currencyName1)?.quotes[
        `${currencyName1}${currencyName2}`
      ],
    [currencyName1, currencyName2, rates]
  );

  const [setCookie] = useCookies(["service"]);

  useEffect(() => {
    dispatch(getTransferRates());
  }, []);

  return (
    <div
      className={`sm:mx-auto mt-[-50px]  mb-6 max-w-[547.09px] bg-[#4F3970] rounded-[23px] py-[27px] mx-8 px-[38px] ${styles}`}
    >
      {type === 2 ? (
        <h4 className="text-[16px] mb-2 md:mb-3 md:text-[20px] text-[#FFF] font-[600] w-full">
          Transfer
        </h4>
      ) : (
        <></>
      )}
      <CurrencyComponent
        defaultCurrency={"gbp"}
        title={"You Send"}
        items={["GBP", "NGN", "EUR", "USD"]}
        position="top-[75px]  left-0 z-[1]   "
        onChangeCurrency={(curr) => {
          form.setFieldValue("currency", curr);

          const rate = rates?.find((rate) => rate?.currency === curr)?.quotes[
            `${curr}${currencyName2}`
          ];

          setCurrency2(currency1 * rate);

          form.setFieldValue("recieverAmount", currency1 * rate);
        }}
        onChange={(e) => {
          const value = e.target.value;
          setCurrency1(value);

          setCurrency2(value * rate);
          form.setFieldValue("amount", value);
          form.setFieldValue("recieverAmount", value * rate);
        }}
        inputStyles={inputStyles}
        value={currency1}
        currency={currencyName1}
        setSelected={setCurrencyName1}
      />
      <CurrencyComponent
        items={["GBP", "NGN", "EUR", "USD"]}
        inputStyles={inputStyles}
        position="top-[75px]  left-0 z-[1]   "
        defaultCurrency={"naira"}
        setSelected={setCurrencyName2}
        title={"Receiver gets"}
        onChangeCurrency={(curr) => {
          const rate = rates?.find((rate) => rate?.currency === currencyName1)
            ?.quotes[`${currencyName1}${curr}`];
          form.setFieldValue("recipientCurrency", curr);
          setCurrency2(currency1 * rate);
          form.setFieldValue("recieverAmount", currency1 * rate);
        }}
        onChange={(e) => {
          const value = e.target.value;
          setCurrency2(e.target.value);
          const rate = rates?.find((rate) => rate?.currency === currencyName1)
            ?.quotes[`${currencyName1}${currencyName2}`];

          setCurrency1(value / rate);
          form.setFieldValue("amount", value / rate);
          form.setFieldValue("recieverAmount", e.target.value);
        }}
        value={currency2}
        currency={currencyName2}
      />
      <div className="w-full flex cursor-pointer  flex-col items-center gap-4 mt-2">
        <h4 className="text-[14px] md:text-base text-[#FFF] leading-5 font-[400]">
          Exchange rate: 1 {currencyName1} = {rate} {currencyName2}
        </h4>
        {type === 2 ? (
          <></>
        ) : (
          <div
            onClick={() => {
              setCookie(
                "curency-1",
                { name: currencyName1, value: currency1 },
                {
                  secure: true,
                }
              );
              setCookie(
                "curency-2",
                { name: currencyName2, value: currency2 },
                {
                  secure: true,
                }
              );

              navigate("/sign-in");
            }}
            className="flex justify-center cursor-pointer items-center group hover:bg-transparent transition-all duration-500 ease-in-out border-[2px] hover:border-[#fff] bg-[#EDF2FF] w-[153px] rounded-[10px] h-[36px] "
          >
            <h4 className="text-base  group-hover:text-[#FFF] text-[#1C2C57]  font-[600]">
              Go
            </h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExchangeRate;
