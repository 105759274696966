import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import SideBar from "../Components/Bars/SideBar";
import Button from "../Components/Inputs/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import TextInput from "../Components/Inputs/TextInput";
import { formHandler } from "../utils/formhandler";
import SuccessMessage from "../Components/Utils/SuccessMessage";
import { beneficiariesSchema } from "../utils/validationSchemas";
import { createBeneficiary } from "../store/actions/beneficiaries/createBeneficiary";
import { verifyBank } from "../store/actions/banks/verifyBank";
import { Autocomplete, Stack, TextField } from "@mui/material";

const AddBeneficiaries = () => {
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const { data: bankData } = useSelector((state) => state?.banks);

  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      fullName: "",
      accountNumber: "",
      password: "",
      swiftCode: "",
      bankName: null,
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: beneficiariesSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const data = {
        ...values,
        bankName: values?.bankName?.value,
      };

      dispatch(
        createBeneficiary({
          cb: () => {
            resetForm();
            setSubmitting(false);
            setShowSuccess(true);
          },
          payload: data,
          failed: () => {
            setSubmitting(false);
          },
        })
      );
    },
  });

  const [gettingAccountName, setgettingAccountName] = useState(false);
  useEffect(() => {
    const _account_number = form?.values?.accountNumber;
    const code = form?.values?.bankName?.code;
    if (_account_number?.length === 10) {
      setgettingAccountName(true);
      dispatch(
        verifyBank({
          payload: {
            bankCode: code,
            accountNumber: _account_number,
          },
          cb: (accountName) => {
            form.setFieldValue("fullName", accountName);
          },
          deleteLastDegit: () => {
            form.setFieldValue("accountNumber", _account_number?.slice(0, 9));
          },
          setgettingAccountName,
        })
      );
    } else form.setFieldValue("fullName", "");
  }, [form?.values?.bankName, form?.values?.accountNumber]);

  useEffect(() => {
    form.setFieldValue("swiftCode", form?.values?.bankName?.code);
  }, [form?.values?.bankName]);

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Add Beneficiaries"}
          description={""}
          name={"Chatta Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <SideBar />
          <div className="flex-1 mb-14  pt-4 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <h4 className="font-[600] text-lg mb-1">Add Beneficiaries</h4>
            <h4 className="font-[400] text-[14px">
              Simplify transfer by adding accounts to beneficiary list
            </h4>
            <div className="mt-5">
              <div className="flex w-[80%] md:w-[799px] mb-6 gap-2  flex-col  justify-between">
                <div className="w-[323px]">
                  <h4 className="text-[#707070] font-[400] text-[14px] mb-1 leading-[21.94px]">
                    Bank Name
                  </h4>
                  <Stack spacing={2} sx={{ width: "100%" }}>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={bankData?.map((i) => ({
                        ...i,
                        label: i.name,
                        name: i.name,
                        value: i.name,
                      }))}
                      value={form?.values?.bankName}
                      onChange={(_, newValue) =>
                        form?.setFieldValue("bankName", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          id="bank_name"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              color: "#000",
                              height: "44px",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                              // Class for the border around the input field
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#999999",
                                borderRadius: "8px",
                                borderWidth: "1.3px",
                                // background: "#FFF",
                                color: "#000",
                                // height: "44px",
                              },
                            },
                            "&.Mui-focused": {
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#999999",
                                borderRadius: "8px",
                                borderWidth: "1.3px",
                                color: "#000",
                              },
                            },
                            // Class for the label of the input field
                            "& .MuiInputLabel-outlined": {
                              color: "#131E3D",
                              fontFamily: "Montserrat",
                              fontWeight: "400",
                              color: "#000",
                            },
                          }}
                        />
                        // <input

                        //   // value={value}
                        //   // onChange={handleNumbers}
                        //   // id={id}
                        //   // name={id}
                        //   type={"text"}
                        //   placeholder={"placeholder"}
                        //   className={``}

                        //   // onClick={(e) => e.stopPropagation()}
                        // />
                      )}
                    />
                  </Stack>
                  {form?.errors?.bankName ? (
                    <h6 className=" text-[0.75rem] mt-[6px] mb-2 text-error text-left w-full">
                      {form?.errors?.bankName}
                    </h6>
                  ) : (
                    <></>
                  )}
                </div>
                <TextInput
                  title={"IBAN/Account Number"}
                  {...formHandler("accountNumber", form)}
                  type={"text"}
                  placeholder={""}
                  mainClasses={"  md:w-[323px]"}
                />
                <TextInput
                  title={"Recipient Name"}
                  {...formHandler("fullName", form)}
                  type={"text"}
                  placeholder={""}
                  disabled={true}
                  loading={gettingAccountName}
                  mainClasses={"  md:w-[323px]"}
                />
                <TextInput
                  title={"BIC/Swift Code"}
                  {...formHandler("swiftCode", form)}
                  type={"text"}
                  placeholder={""}
                  mainClasses={"  md:w-[323px]"}
                />
                <TextInput
                  title={"Input Password"}
                  {...formHandler("password", form)}
                  type={"password"}
                  placeholder={""}
                  mainClasses={"  md:w-[323px]"}
                />
                <Button
                  bg={"#192851"}
                  title={"Save"}
                  textStyles="font-[600] text-[18px]"
                  mainClasses="h-[56px]  mt-8"
                  // onClick={form.handleSubmit}
                  onClick={form.handleSubmit}
                  loading={form.isSubmitting}
                  // disabled={!form.dirty || !form.isValid || form.isSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {showSuccess ? (
        <SuccessMessage
          handleClose={() => {
            setShowSuccess(false);
            navigate("/beneficiaries");
          }}
          message={"Beneficiary Added"}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AddBeneficiaries;
