import React, { useEffect } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import OTPMain from "../Components/Auth/OTPMain";

const OTP = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="font-mons">
      <SEO
        title={"Login to Chatta Finance"}
        description={""}
        name={"Chatta Finance"}
        type={"article"}
      />
      <Header />
      <div className="font-mons bg-[url('./Assets/Images/auth-background.png')] bg-cover h-screen flex justify-center items-center w-full pt-14">
        <OTPMain />
      </div>
      <Footer />
    </div>
  );
};

export default OTP;
