import React, { useMemo, useState } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillCaretDownFill } from "react-icons/bs";
import { TbLogout2 } from "react-icons/tb";
import { IoHelpCircleOutline } from "react-icons/io5";
import { CiSettings } from "react-icons/ci";
import { BsFillPersonFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../store/reducer/userSlice";
import { FaChevronDown } from "react-icons/fa6";
import { RiUserSharedLine } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineMenu } from "react-icons/md";
import { toggleSideMenu } from "../../store/reducer/uiSlice";
import { Cookies } from "react-cookie";
const Menu = ({ menuOpen, setMenuOpen }) => {
  const pathName = useLocation().pathname;
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const handleClickLink = (param) => {
    navigate(param);
    setMenuOpen(false);
  };
  const links = useMemo(() => {
    return user?.email
      ? [
          { id: 1, textLink: "Dashboard", link: "/dashboard" },
          { id: 3, textLink: "Financial News", link: "/financial-news" },

          { id: 4, textLink: "About", link: "/about-us" },
          { id: 5, textLink: "Services", link: "#" },
        ]
      : [
          { id: 1, textLink: "Home", link: "/" },
          { id: 3, textLink: "Sign in", link: "/sign-in" },

          { id: 4, textLink: "About", link: "/about-us" },
          { id: 5, textLink: "Services", link: "#" },
        ];
  }, [user]);

  return (
    <div className="">
      <div className="flex flex-col items-center menu-height lg:hidden ">
        <div className="pt-20">
          <ul className="">
            {links.map(({ id, textLink, link, hasOthers }) => (
              <li
                className={`text-[#192851] font-normal flex gap-1 justify-center items-center text-base py-6 text-center ${
                  pathName == link && "active2"
                }`}
                key={id}
                onClick={() => handleClickLink(link)}
              >
                <p href={link}>{textLink}</p>
                {hasOthers && (
                  <HiOutlineChevronDown
                    className="group-hover:text-gray-800 mt-[1px]"
                    size={20}
                    color="#475467"
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { dataDetails } = useSelector((state) => state.profile);

  const cookies = new Cookies();
  const handleLogOut = () => {
    cookies.remove("accessToken");
    localStorage.clear();
    dispatch(logOut());
  };

  return (
    <>
      {/* {menuOpen && !user?.email && (
        <div className="w-screen h-screen bg-[rgba(0,0,0,0.58)] absolute top-0 left-0 z-30"></div>
      )} */}
      <nav id="header" className="fixed   w-full z-30 top-0 bg-[#4F3970]">
        <div className="w-full px-4 sm:pl-10 sm:pr-8 mx-auto flex  justify-between mt-0 ">
          <div className="flex py-[16px] gap-10 items-center">
            {user?.email ? (
              <div className="flex sm:gap-4 laptop:hidden h-[2rem] ">
                <MdOutlineMenu
                  size={"30px"}
                  color="#FFF"
                  onClick={() => {
                    dispatch(toggleSideMenu());
                  }}
                  className="cursor-pointer"
                />
              </div>
            ) : (
              <></>
            )}
            <div className="pl-4 hidden sm:flex cursor-pointer  gap-4 items-center">
              <a href="/">
                <img
                  src={require("../../Assets/Logo/logo.png")}
                  className="w-[62px] md:w-[70px] object-contain h-[40px] md:h-[48px]"
                />
              </a>
            </div>
          </div>
          <div className=" lg:flex lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20">
            {user?.email ? (
              <ul className="list-reset lg:flex justify-end flex-1 items-center">
                <li
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                  className={`mr-3 group flex h-full items-center gap-2 px-4 hover:bg-[rgba(57,90,181,0.19)] transition-all duration-500 ease-in-out  py-2 cursor-pointer ${
                    pathname === "/dashboard" ? "bg-[rgba(57,90,181,0.19)]" : ""
                  }`}
                >
                  <p
                    className={` min-w-[70px] text-center inline-block text-[#fff] no-underline  text-[14px] font-[500]  transition duration-300 ease-in-out `}
                  >
                    Dashboard
                  </p>
                </li>
                <li
                  onClick={() => {
                    navigate("/financial-news");
                  }}
                  className={`mr-3 group flex h-full items-center gap-2 px-4 hover:bg-[rgba(57,90,181,0.19)] transition-all duration-500 ease-in-out  py-2 cursor-pointer ${
                    pathname === "/sign-in" ? "bg-[rgba(57,90,181,0.19)]" : ""
                  }`}
                >
                  <p
                    className={` min-w-[70px] text-center inline-block text-[#fff] no-underline  text-[14px] font-[500]  transition duration-300 ease-in-out `}
                  >
                    Financial News
                  </p>
                </li>

                <li
                  onClick={() => {
                    navigate("/about-us");
                  }}
                  className={`mr-3 group flex h-full items-center gap-2 px-4 hover:bg-[rgba(57,90,181,0.19)] transition-all duration-500 ease-in-out  py-2 cursor-pointer ${
                    pathname === "/about-us" ? "bg-[rgba(57,90,181,0.19)]" : ""
                  }`}
                >
                  <p className=" group-hover:text-[#fff] inline-block text-[#fff] no-underline  text-[14px] font-[500]  transition duration-300 ease-in-out ">
                    About
                  </p>
                </li>

                <li
                  tabIndex={1}
                  className={`relative group mr-3 group flex h-full items-center gap-2 px-4 hover:bg-[rgba(57,90,181,0.19)] transition-all duration-500 ease-in-out  py-2 cursor-pointer ${
                    pathname === "/services" ? "bg-[rgba(57,90,181,0.19)]" : ""
                  }`}
                >
                  <div className="flex gap-[2px] items-center">
                    <p className=" group-hover:text-[#fff] inline-block text-[#fff] no-underline  text-[14px] font-[500]  transition duration-300 ease-in-out ">
                      Services
                    </p>
                    <FaChevronDown color="#fff" size={"12px"} />
                  </div>
                  <div className="absolute hidden group-focus:block top-[72px] right-0 w-[192px] overflow-hidden bg-[#F9F9F9] rounded-[6px] ">
                    <div
                      onClick={() => {
                        navigate("/transfer");
                      }}
                      className="w-full flex h-[33px] items-center py-2 px-3 gap-2 cursor-pointer  hover:bg-[#E2E1E1]"
                    >
                      <img
                        src={require("../../Assets/Icons/transaction.png")}
                        alt="currency"
                        className="w-[14.33px] h-[10px] rounded-full"
                      />
                      <h4 className="text-[14px] text-[#000000] font-[400]">
                        Make a transfer
                      </h4>
                    </div>
                    <div
                      onClick={() => {
                        navigate("/exchange-rate");
                      }}
                      className="w-full flex h-[33px] items-center py-2 px-3 gap-2 cursor-pointer  hover:bg-[#E2E1E1]"
                    >
                      <img
                        src={require("../../Assets/Icons/exchange.png")}
                        alt="currency"
                        className="w-[15px] h-[15px] rounded-full"
                      />
                      <h4 className="text-[14px] text-[#000000] font-[400]">
                        Exchange rates
                      </h4>
                    </div>
                    <div
                      onClick={() => {
                        navigate("/blog");
                      }}
                      className="w-full flex h-[33px] items-center py-2 px-3 gap-2 cursor-pointer  hover:bg-[#E2E1E1]"
                    >
                      <img
                        onClick={() => {}}
                        src={require("../../Assets/Icons/list.png")}
                        alt="currency"
                        className="w-[12.5px] h-[10px] rounded-full"
                      />
                      <h4 className="text-[14px] text-[#000000] font-[400]">
                        Financial News
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            ) : (
              <ul className="list-reset lg:flex justify-end flex-1 items-center">
                <li
                  onClick={() => {
                    navigate("/");
                  }}
                  className={`mr-3 group flex h-full items-center gap-2 px-4 hover:bg-[rgba(57,90,181,0.19)] transition-all duration-500 ease-in-out  py-2 cursor-pointer ${
                    pathname === "/" ? "bg-[rgba(57,90,181,0.19)]" : ""
                  }`}
                >
                  <p
                    className={` min-w-[70px] text-center inline-block text-[#fff] no-underline  text-[14px] font-[500]  transition duration-300 ease-in-out `}
                  >
                    Home
                  </p>
                </li>
                <li
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                  className={`mr-3 group flex h-full items-center gap-2 px-4 hover:bg-[rgba(57,90,181,0.19)] transition-all duration-500 ease-in-out  py-2 cursor-pointer ${
                    pathname === "/sign-in" ? "bg-[rgba(57,90,181,0.19)]" : ""
                  }`}
                >
                  <p
                    className={` min-w-[70px] text-center inline-block text-[#fff] no-underline  text-[14px] font-[500]  transition duration-300 ease-in-out `}
                  >
                    Sign in
                  </p>
                </li>

                <li
                  onClick={() => {
                    navigate("/about-us");
                  }}
                  className={`mr-3 group flex h-full items-center gap-2 px-4 hover:bg-[rgba(57,90,181,0.19)] transition-all duration-500 ease-in-out  py-2 cursor-pointer ${
                    pathname === "/about-us" ? "bg-[rgba(57,90,181,0.19)]" : ""
                  }`}
                >
                  <p className=" group-hover:text-[#fff] inline-block text-[#fff] no-underline  text-[14px] font-[500]  transition duration-300 ease-in-out ">
                    About Us
                  </p>
                </li>

                <li
                  tabIndex={1}
                  className={`relative group mr-3 group flex h-full items-center gap-2 px-4 hover:bg-[rgba(57,90,181,0.19)] transition-all duration-500 ease-in-out  py-2 cursor-pointer ${
                    pathname === "/services" ? "bg-[rgba(57,90,181,0.19)]" : ""
                  }`}
                >
                  <div className="flex gap-[2px] items-center">
                    <p className=" group-hover:text-[#fff] inline-block text-[#fff] no-underline  text-[14px] font-[500]  transition duration-300 ease-in-out ">
                      Services
                    </p>
                    <FaChevronDown color="#fff" size={"12px"} />
                  </div>
                  <div className="absolute hidden group-focus:block top-[72px] right-0 w-[192px] overflow-hidden bg-[#F9F9F9] rounded-[6px] ">
                    <div
                      onClick={() => {
                        navigate("/sign-in");
                      }}
                      className="w-full flex h-[33px] items-center py-2 px-3 gap-2 cursor-pointer  hover:bg-[#E2E1E1]"
                    >
                      <img
                        src={require("../../Assets/Icons/transaction.png")}
                        alt="currency"
                        className="w-[14.33px] h-[10px] rounded-full"
                      />
                      <h4 className="text-[14px] text-[#000000] font-[400]">
                        Make a transfer
                      </h4>
                    </div>
                    <div
                      onClick={() => {
                        navigate("/exchange-rate");
                      }}
                      className="w-full flex h-[33px] items-center py-2 px-3 gap-2 cursor-pointer  hover:bg-[#E2E1E1]"
                    >
                      <img
                        src={require("../../Assets/Icons/exchange.png")}
                        alt="currency"
                        className="w-[15px] h-[15px] rounded-full"
                      />
                      <h4 className="text-[14px] text-[#000000] font-[400]">
                        Exchange rates
                      </h4>
                    </div>
                    <div
                      // onClick={handleLogOut}
                      className="w-full flex h-[33px] items-center py-2 px-3 gap-2 cursor-pointer  hover:bg-[#E2E1E1]"
                    >
                      <img
                        onClick={() => {}}
                        src={require("../../Assets/Icons/list.png")}
                        alt="currency"
                        className="w-[12.5px] h-[10px] rounded-full"
                      />
                      <h4 className="text-[14px] text-[#000000] font-[400]">
                        Financial News
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            )}
          </div>
          <div className="block py-[16px] lg:hidden pr-4">
            {user?.email ? (
              <></>
            ) : (
              <button
                id="nav-toggle"
                className="flex items-center p-1 text-[#FFF] focus:outline-none focus:shadow-outlinet"
              >
                {!menuOpen ? (
                  <MdOutlineMenu
                    size={"30px"}
                    onClick={() => setMenuOpen(!menuOpen)}
                    className="cursor-pointer"
                  />
                ) : (
                  <span onClick={() => setMenuOpen(!menuOpen)}>
                    <AiOutlineClose size={24} color="white" />
                  </span>
                )}
              </button>
            )}
          </div>

          {user?.email ? (
            <div className=" gap-2 flex ">
              <div tabIndex={1} className="flex items-center group relative">
                {dataDetails?.profilePicture ? (
                  <img
                    src={dataDetails?.profilePicture}
                    alt="profile-picture"
                    className="cursor-pointer w-[40px] h-[40px] rounded-full object-cover"
                  />
                ) : (
                  <>
                    <BsFillPersonFill
                      onClick={() => {}}
                      color="#FFF"
                      size={35}
                      className=" cursor-pointer"
                    />

                    <BsFillCaretDownFill
                      onClick={() => {}}
                      color="#FFF"
                      size={16}
                      className="cursor-pointer ml-[-2px]"
                    />
                  </>
                )}

                <div className="absolute hidden group-focus:block top-[56px] right-3 w-[192px] py-4  bg-[#F9F9F9] rounded-[6px] overflow-hidden">
                  <div className="w-[90%] mx-auto ">
                    <h4 className="text-[14px] text-center font-[700]">
                      Hi, {user?.fullName?.split(" ")[0]}
                    </h4>
                    <h4 className="text-[11px] lowercase text-ellipsis overflow-hidden breakw text-center font-[400]">
                      {user?.email}
                    </h4>
                    <h4 className="text-[11px] text-center font-[400]">
                      Referral code: {user?.referralCode}
                    </h4>
                  </div>
                  <div className="border-b-[0.7px] border-b-[#E3E1E1] mt-3 mb-2"></div>
                  <div
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                    className="w-full flex h-[31px] items-center py-2 px-5 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
                  >
                    <RxDashboard size="15px" />
                    <h4 className="text-[12px] font-[500]">Dashboard</h4>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/refer");
                    }}
                    className="w-full flex h-[31px] items-center py-2 px-5 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
                  >
                    <div className="w-[16px] ">
                      <img
                        src={require(".././../Assets/Icons/refer.png")}
                        className="w-[15px] h-[15px]"
                        alt="kyc"
                      />
                    </div>
                    <h4 className="text-[12px] font-[500]">Refer and Earn</h4>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/help");
                    }}
                    className="w-full flex h-[31px] items-center py-2 px-5 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
                  >
                    <IoHelpCircleOutline size="17px" />
                    <h4 className="text-[12px] font-[500]">Help</h4>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/settings");
                    }}
                    className="w-full flex h-[31px] items-center py-2 px-5 gap-2 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
                  >
                    <div className="w-[20px] ">
                      <CiSettings size="20px" />
                    </div>
                    <h4 className="text-[12px] font-[500]">Settings</h4>
                  </div>
                  <div
                    onClick={handleLogOut}
                    className="w-full flex h-[31px] items-center py-2 px-5 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
                  >
                    <div className="w-[16px] ">
                      <img
                        src={require(".././../Assets/Icons/logout.png")}
                        className="w-[15px] h-[15px]"
                        alt="kyc"
                      />
                    </div>
                    <h4 className="text-[12px] font-[500]">Log out</h4>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {!user?.email ? <div className="hidden lg:block"></div> : <></>}
        </div>
      </nav>
      {menuOpen && !user?.email && (
        <div
          className={`fixed mt-[60px] w-full h-full z-50 bg-white ${
            menuOpen ? "menu-wrapper " : " menu-hidden"
          }`}
        >
          <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
      )}
    </>
  );
};

export default Header;
