import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import SideBar from "../Components/Bars/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { formHandler } from "../utils/formhandler";
import SuccessMessage from "../Components/Utils/SuccessMessage";
import ConfirmDelete from "../Components/Modals/ConfirmDelete";
import Search from "../Components/Inputs/Search";
import Loader from "../Components/Utils/Loader";
import { deleteBeneficiary } from "../store/actions/beneficiaries/deleteBeneficiary";
import { getBeneficiaries } from "../store/actions/beneficiaries/getBeneficiaries";

const Beneficiaries = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const from = queryParams.get("from");

  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const handleDelete = (id) => {
    dispatch(
      deleteBeneficiary({
        id,
        cb: () => {
          setShowSuccess(true);
          setShowConfirm(false);
        },
        failed: () => {
          setShowConfirm(false);
        },
      })
    );
  };

  const { pending, data } = useSelector((state) => state?.beneficiaries);
  useEffect(() => {
    dispatch(getBeneficiaries());
  }, []);

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Your Beneficiaries"}
          description={""}
          name={"Chatta Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <SideBar />
          <div className="flex-1 mb-14 max-w-[100vw]  pt-4 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <div className="flex items-center  flex-wrap  justify-between">
              <h4 className="font-[600] text-lg mb-1">Beneficiaries</h4>
              <Search
                onChange={(e) => {
                  setSearchText(e?.target?.value);
                }}
                value={searchText}
                id={"search"}
                type={"text"}
                placeholder={"Search name"}
                mainClasses={"w-[333px]"}
              />
            </div>

            {pending & (data?.length < 1) ? (
              <Loader />
            ) : (
              <div className="mt-5 overflow-x-auto ">
                <div className="flex w-[1043px] mb-6  flex-col  justify-between">
                  <div className="h-[75%] rounded-[20px] border-[#D1D1D1] border-[0.8px] p-5 bg-[#FFF]">
                    <div className="flex border-b-[#D1D1D1]  border-b-[0.5px] py-3">
                      <h4 className="font-[500] text-base text-[#707070] w-[120px] ">
                        No.
                      </h4>
                      <h4 className="font-[500] text-base text-[#707070] w-[250px]">
                        Recipient Name
                      </h4>
                      <h4 className="font-[500] text-base text-[#707070] w-[240px] ">
                        Bank Name
                      </h4>
                      <h4 className="font-[500] text-base text-[#707070] w-[180px] ">
                        Account Number
                      </h4>
                      <h4 className="font-[500] text-base text-[#707070] w-[100px] ">
                        Action
                      </h4>
                    </div>
                    <div className="overflow-y-auto h-[80%] ">
                      {data
                        ?.filter((ben) =>
                          searchText?.length > 0
                            ? ben?.fullName
                                ?.toLowerCase()
                                ?.includes(searchText?.toLowerCase())
                            : true
                        )
                        ?.map((item) => {
                          return (
                            <div
                              key={item?.id}
                              className={`flex border-b-[#D1D1D1]  border-b-[0.5px] py-3 ${
                                // from ? "cursor-pointer" : ""
                                ""
                              }`}
                            >
                              <h4 className="font-[500] text-base text-[#000000] w-[120px] ">
                                {item?.id}
                              </h4>
                              <h4 className="font-[500] text-base text-[#000000] w-[250px] ">
                                {item?.fullName}
                              </h4>
                              <h4 className="font-[500] text-base text-[#000000] w-[240px]">
                                {item?.bankName}
                              </h4>
                              <h4 className="font-[500] text-base text-[#000000] w-[180px] ">
                                {item?.accountNumber}
                              </h4>
                              {from ? (
                                <h4
                                  onClick={() => {
                                    navigate(`/${from}`, { state: item });
                                  }}
                                  className="cursor-pointer font-[500] text-base text-[#395AB5] underline w-[100px] "
                                >
                                  Select
                                </h4>
                              ) : (
                                <h4
                                  onClick={() => {
                                    setShowConfirm(item?.id);
                                  }}
                                  className="cursor-pointer font-[500] text-base text-[#E00202] underline w-[100px] "
                                >
                                  Delete
                                </h4>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
      {showConfirm ? (
        <ConfirmDelete
          handleConfirm={() => {
            handleDelete(showConfirm);
          }}
          handleClose={() => {
            setShowConfirm(false);
            // navigate("/sign-in");
          }}
          pendingConfirm={pending}
          message={"Are you sure you want to remove beneficiary?"}
        />
      ) : (
        <></>
      )}
      {showSuccess ? (
        <SuccessMessage
          handleClose={() => {
            setShowSuccess(false);
          }}
          message={"Beneficiary Deleted"}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Beneficiaries;
