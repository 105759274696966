import React, { useEffect } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";

const Services = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="font-mons">
      <SEO
        title={"Services"}
        description={""}
        name={"Chatta Finance"}
        type={"article"}
      />
      <Header />
      <div className="bg-[#EDF2FF] pt-24">
        <div className="container pl-3 mx-auto lg:h-[628px] flex flex-wrap flex-col md:flex-row lg:justify-between  items-center"></div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
