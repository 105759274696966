import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Post from "../Components/Blog/Post";
import Post2 from "../Components/Blog/Post2";

const Blog = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      fullName: "",
      bankName: "",
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    // validationSchema: signupSchema,
    onSubmit: (values, { setSubmitting }) => {
      const data = {
        ...values,
      };

      // dispatch(register({ navigate, data, setSubmitting }));
    },
  });

  const [activeHeader, setActiveHeader] = useState(1);
  const [activePost, setActivePost] = useState(0);
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    setSelectedPost(null);
  }, [activeHeader]);

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Blog"}
          description={""}
          name={"Chatta Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <div className="flex-1 flex gap-8 lg:gap-14 max-w-[100vw] overflow-x-auto pt-10 pb-6 sm:pb-9 px-5 md:px-9 bg-[#F1F1F1]">
            <div className="flex flex-col flex-1 ">
              <div className="flex items-center gap-2  mb-2">
                {["All", "Latest"].map((item, index) => {
                  return (
                    <h4
                      onClick={() => {
                        setActiveHeader(index);
                      }}
                      key={index}
                      className={`text-[16px] cursor-pointer sm:text-[18px] font-[600] ${
                        activeHeader === index
                          ? "text-[#395AB5]"
                          : "text-[#707070]"
                      }`}
                    >
                      {item}
                    </h4>
                  );
                })}
              </div>
              <Post
                handleClick={() => setSelectedPost((prev) => !prev)}
                type={"wide"}
                mode={selectedPost ? "viewAll" : null}
              />
              {selectedPost ? (
                <div className="my-10"></div>
              ) : (
                <>
                  {" "}
                  <div className="w-full flex justify-center items-center gap-1">
                    {[1, 2, 3].map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => setActivePost(index)}
                          className={`transition-all ease-in-out duration-300 cursor-pointer  h-[10px] ${
                            activePost === index
                              ? "w-[26.67px] rounded-[6px] bg-[#395AB5]"
                              : "w-[10px] rounded-full bg-[#C2C2C2]"
                          }`}
                        ></div>
                      );
                    })}
                  </div>
                  <div className="border-t border-t-[#9B9A9A] my-7"></div>
                </>
              )}
              <div className="w-full">
                <div className="flex  items-center justify-between gap-2 mb-2">
                  <h4
                    className={`text-[16px] cursor-pointer sm:text-[18px] font-[600] text-[#707070]`}
                  >
                    {selectedPost ? "Related News" : "Today"}
                  </h4>
                  {selectedPost ? (
                    <></>
                  ) : (
                    <h4
                      onClick={() => {}}
                      className={`text-[16px] cursor-pointer sm:text-[18px] font-[600] text-[#395AB5]`}
                    >
                      More
                    </h4>
                  )}
                </div>
                <Post2
                  handleClick={() => setSelectedPost(true)}
                  type={selectedPost ? 2 : null}
                />
                <Post2
                  handleClick={() => setSelectedPost(true)}
                  type={selectedPost ? 2 : null}
                />
                <Post2
                  handleClick={() => setSelectedPost(true)}
                  type={selectedPost ? 2 : null}
                />
              </div>
            </div>
            <div className="flex flex-col w-[348px] ">
              <h4 className="text-[16px] mb-2 sm:text-[18px] font-[600] text-[#707070]">
                Featured News
              </h4>
              <Post handleClick={() => setSelectedPost(true)} />
              <Post handleClick={() => setSelectedPost(true)} />
              <Post handleClick={() => setSelectedPost(true)} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Blog;
