export const baseURL = "https://strong.chattafinance.com/api/v1/";

export const REGISTER = "auth/register";
export const LOGIN = "auth/login";
export const GOOGLE_AUTH = "auth/google?accessToken=";
export const FACEBOOK_AUTH = "auth/facebook?accessToken=";
export const FORGOT_PASSWORD = "auth/forgotPassword";
export const CHANGE_PASSWORD = "auth/changePassword";
export const RESET_PASSWORD = "auth/resetPassword?resetCode=829986";
export const VERIFY_EMAIL = "auth/verify";
export const RESEND_TOKEN = "auth/resend";

// Beneficiaries
export const BENEFICIARIES = "beneficiaries";

// Transactions
export const TRANSACTIONS = "transactionHistories";
export const TRANSFER = "transfer?currency=";
export const DIRECT_TRANSFER = "transfer/direct?currency=";
export const TRANSFER_RATES = "transfer/rates";

// Payment Method
export const PAYMENT_METHOD = "paymentMethod";

// Recurring
export const RECURRING = "recurring";

// Profile
export const PROFILE = "profile";
export const PROFILE_KYC = "profile/kyc";

// Recurring
export const PAYMENTS = "payments";

// Banks
export const BANKS = "transfer/banks";
export const VERIFY_BANK = "transfer/verify-account";

// Wallet
export const FUND_WALLET = "wallet/fund?currency=";
export const GET_WALLET = "wallet";
export const GET_WALLET_BY_CURRENCY = "wallet/currency?currency=";

// Dashboard
export const GET_TRANSACTION_DISTRIBUTION = "dashboard/transactions";
export const GET_TRANSACTION_COUNTRIES = "dashboard/countries";
