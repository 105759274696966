import React, { useState } from "react";
import { RxDashboard } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toggleSideMenu } from "../../store/reducer/uiSlice";
import { MdOutlineClose } from "react-icons/md";
import { logOut } from "../../store/reducer/userSlice";
import { FaChevronDown, FaChevronRight, FaChevronUp } from "react-icons/fa6";
import { Cookies } from "react-cookie";

const SideBar = () => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const { openSideMenu } = useSelector((state) => state.ui);

  const handleLogOut = () => {
    localStorage.clear();
    cookies.remove("accessToken");
    dispatch(logOut());
  };

  const [moreBeneficiaries, setMoreBeneficiaries] = useState(
    pathname?.includes("beneficiaries") || false
  );
  const [moreWallet, setMoreWallet] = useState(
    pathname?.includes("wallet") || false
  );

  return (
    <>
      {openSideMenu ? (
        <div
          onClick={() => {
            dispatch(toggleSideMenu());
          }}
          className="bg-[rgba(255,255,255,0.3)] laptop:hidden z-40 fixed top-0 left-0 w-full h-screen"
        ></div>
      ) : (
        <></>
      )}
      <div
        className={` laptop:block  bg-[#FFF] laptop:relative  left-0 z-50 laptop:z-auto w-[313px] pt-2 h-[100vh] laptop:h-auto min-h-[80vh] flex flex-col  fixed top-[0px] ${
          openSideMenu ? "" : "hidden"
        }`}
      >
        <div className="flex justify-between laptop:hidden items-center py-2 px-8">
          <img
            src={require("../../Assets/Logo/logo-transparent.png")}
            className="w-[45px]  object-contain h-[48px]"
          />

          <div className="  ">
            <MdOutlineClose
              color="#000"
              size={"26px"}
              onClick={() => {
                dispatch(toggleSideMenu());
              }}
              className="cursor-pointer"
            />
          </div>
        </div>
        <div className="w-full flex-[3] flex flex-col gap-3 py-5 px-3">
          <div
            onClick={() => {
              dispatch(toggleSideMenu());
              navigate("/dashboard");
            }}
            className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
              pathname === "/dashboard" ? "bg-[#E2E1E1]" : ""
            }`}
          >
            <div className="w-[20px] ">
              <RxDashboard color="#000" size="20px" />
            </div>
            <h4 className="text-[16px] text-[#000] font-[400]">Dashboard</h4>
          </div>
          {/* <div
            // onClick={handleLogOut}
            className="w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
          >
            <div className="w-[20px] ">
              <img
                src={require(".././../Assets/Icons/wallet.png")}
                className="w-[18px] h-[18px] object-contain"
                alt="wallet"
              />
            </div>
            <h4 className="text-[16px] font-[400]">Wallet</h4>
          </div> */}
          <div className="flex flex-col gap-1">
            <div
              onClick={() => {
                setMoreWallet((prev) => !prev);
                dispatch(toggleSideMenu());
                navigate("/fund-wallet");
              }}
              className="w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
            >
              <div className="w-[20px] ">
                <img
                  src={require(".././../Assets/Icons/wallet.png")}
                  className="w-[18px] h-[18px] object-contain"
                  alt="wallet"
                />
              </div>
              <div className="flex gap-[4px] items-center">
                <h4 className="text-[16px] text-[#000] font-[400]">Wallet</h4>
                {moreWallet ? (
                  <FaChevronDown color="#000" size={"14px"} />
                ) : (
                  <FaChevronRight color="#000" size={"14px"} />
                )}
              </div>
            </div>
            {moreWallet ? (
              <>
                <div
                  onClick={() => {
                    dispatch(toggleSideMenu());
                    navigate("fund-wallet");
                  }}
                  className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
                    pathname === "/fund-wallet" ? "bg-[#E2E1E1]" : ""
                  }`}
                >
                  <div className="w-[20px] opacity-0"></div>
                  <h4 className="text-[16px] text-[#000] font-[400]">
                    Fund Wallet
                  </h4>
                </div>
                <div
                  onClick={() => {
                    dispatch(toggleSideMenu());
                    navigate("fund-wallet");
                  }}
                  className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
                    pathname === "/cards" ? "bg-[#E2E1E1]" : ""
                  }`}
                >
                  <div className="w-[20px] opacity-0"></div>
                  <h4 className="text-[16px] text-[#000] font-[400]">Cards</h4>
                </div>
                <div
                  onClick={() => {
                    dispatch(toggleSideMenu());
                    navigate("fund-wallet");
                  }}
                  className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
                    pathname === "/bank-accounts" ? "bg-[#E2E1E1]" : ""
                  }`}
                >
                  <div className="w-[20px] opacity-0"></div>
                  <h4 className="text-[16px] text-[#000] font-[400]">
                    Bank Accounts
                  </h4>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <div
              onClick={() => {
                dispatch(toggleSideMenu());
                setMoreBeneficiaries((prev) => !prev);
                navigate("/beneficiaries");
              }}
              className="w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
            >
              <div className="w-[20px] ">
                <img
                  src={require(".././../Assets/Icons/beneficiaries.png")}
                  className="w-[18px] h-[18px] object-contain"
                  alt="beneficiaries"
                />
              </div>
              <div className="flex gap-[4px] items-center">
                <h4 className="text-[16px] text-[#000] font-[400]">
                  Manage Beneficiaries
                </h4>
                {moreBeneficiaries ? (
                  <FaChevronDown color="#000" size={"14px"} />
                ) : (
                  <FaChevronRight color="#000" size={"14px"} />
                )}
              </div>
            </div>
            {moreBeneficiaries ? (
              <>
                <div
                  onClick={() => {
                    dispatch(toggleSideMenu());
                    navigate("/beneficiaries");
                  }}
                  className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
                    pathname === "/beneficiaries" ? "bg-[#E2E1E1]" : ""
                  }`}
                >
                  <div className="w-[20px] opacity-0"></div>
                  <h4 className="text-[16px] text-[#000] font-[400]">
                    Beneficiaries
                  </h4>
                </div>
                <div
                  onClick={() => {
                    dispatch(toggleSideMenu());
                    navigate("/add-beneficiaries");
                  }}
                  className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
                    pathname === "/add-beneficiaries" ? "bg-[#E2E1E1]" : ""
                  }`}
                >
                  <div className="w-[20px] opacity-0"></div>
                  <h4 className="text-[16px] text-[#000] font-[400]">
                    Add Beneficiaries
                  </h4>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div
            onClick={() => {
              dispatch(toggleSideMenu());
              navigate("/transfer");
            }}
            className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
              pathname === "/transfer" ? "bg-[#E2E1E1]" : ""
            }`}
          >
            <div className="w-[20px] ">
              <img
                src={require(".././../Assets/Icons/transaction.png")}
                className="w-[18px] h-[18px] object-contain"
                alt="track"
              />
            </div>
            <h4 className="text-[16px] text-[#000] font-[400]">Transfer</h4>
          </div>
          <div
            onClick={() => {
              dispatch(toggleSideMenu());
              navigate("/payments");
            }}
            className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
              pathname === "/payments" ? "bg-[#E2E1E1]" : ""
            }`}
          >
            <div className="w-[20px] ">
              <img
                src={require(".././../Assets/Icons/track.png")}
                className="w-[18px] h-[18px] object-contain"
                alt="track"
              />
            </div>
            <h4 className="text-[16px] text-[#000] font-[400]">
              Track Payments
            </h4>
          </div>
          <div
            onClick={() => {
              dispatch(toggleSideMenu());
              navigate("/recurring-payments");
            }}
            className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
              pathname === "/recurring-payments" ? "bg-[#E2E1E1]" : ""
            }`}
          >
            <div className="w-[20px] ">
              <img
                src={require(".././../Assets/Icons/recurring.png")}
                className="w-[18px] h-[18px] object-contain"
                alt="recurring"
              />
            </div>
            <h4 className="text-[16px] text-[#000] font-[400]">
              Recurring Payments
            </h4>
          </div>
          <div
            onClick={() => {
              dispatch(toggleSideMenu());
              navigate("/transactions");
            }}
            className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
              pathname === "/transactions" ? "bg-[#E2E1E1]" : ""
            }`}
          >
            <div className="w-[20px] ">
              <img
                src={require(".././../Assets/Icons/history.png")}
                className="w-[18px] h-[18px] object-contain"
                alt="transaction-history"
              />
            </div>
            <h4 className="text-[16px] text-[#000] font-[400]">
              Transaction History
            </h4>
          </div>
        </div>
        <div className="w-full flex-1 border-t-[#959595] border-t-[0.7px] flex flex-col gap-3 py-5 px-3">
          <div
            onClick={() => {
              dispatch(toggleSideMenu());
              navigate("/settings/kyc");
            }}
            className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
              pathname.includes("kyc") ? "bg-[#E2E1E1]" : ""
            }`}
          >
            <div className="w-[20px] ">
              <img
                src={require(".././../Assets/Icons/kyc.png")}
                className="w-[18px] h-[18px] object-contain"
                alt="kyc"
              />
            </div>
            <h4 className="text-[16px] text-[#000] font-[400]">
              KYC Verification
            </h4>
          </div>
          <div
            onClick={handleLogOut}
            className="w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
          >
            <div className="w-[20px] ">
              <img
                src={require(".././../Assets/Icons/logout.png")}
                className="w-[18px] h-[18px] object-contain"
                alt="logout"
              />
            </div>
            <h4 className="text-[16px] text-[#000] font-[400]">Log out</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
