import React, { useEffect, useRef, useState } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { FaChevronRight } from "react-icons/fa6";
import { Tooltip } from "@mui/material";

const Help = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [open, setOpen] = React.useState(false);
  const timeOutRef = useRef();
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCopyToClipboard = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);

      setOpen(true);
      setIsCopied("Copied");
    } catch (error) {
      // console.error("Failed to copy text to clipboard:", error);
    }

    timeOutRef.current = setTimeout(() => {
      setIsCopied(null);
      setOpen(false);
    }, 2000);
  };

  useEffect(() => {
    return () => clearTimeout(timeOutRef.current);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Help"}
          description={""}
          name={"Chatta Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <div className="flex-1 justify-center items-end max-w-[100vw] flex overflow-x-auto pt-4 pb-6 px-5 md:px-9">
            <div className="flex flex-col pt-4 pb-6 items-center">
              <div className="rounded-full bg-[#C5D3FC] border-[10px] border-[#fff] z-10 -mb-8 w-[311.19px] h-[311.19px]">
                <img
                  src={require("../Assets/Icons/refer-help.png")}
                  className="w-full h-full object-contain"
                  alt="help"
                />
              </div>
              <div className="w-full px-3 sm:w-[864px] h-[131.25px] mb-2 z-0  bg-[#395AB5] flex justify-center items-center">
                <h4 className="font-[600] text-[26px] text-center mt-2 md:mt-0 sm:text-[30px] text-[#FFF]">
                  Hi, how can we help today?
                </h4>
              </div>
              <div className="flex w-full  my-3 sm:w-[530px] gap-2 cursor-pointer">
                <div className="min-w-[60px] ">
                  <img
                    src={require("../Assets/Icons/refer-phone.png")}
                    className="w-[40px] h-[40px] sm:w-[53px] sm:h-[53px] object-contain"
                    alt="help"
                  />
                </div>
                <div>
                  <h4 className="font-[600] text-[18px] sm:text-[20px] text-[#000]">
                    Phone Support
                  </h4>

                  <h4
                    onClick={() => handleCopyToClipboard("+447218181819")}
                    className="font-[400] text-[13px] sm:text-[14px] text-[#000]"
                  >
                    Call us at{" "}
                    <Tooltip
                      open={isCopied || open}
                      onClose={handleClose}
                      onOpen={handleOpen}
                      title={isCopied ? "Copied!" : "Copy"}
                      arrow
                    >
                      +44 7218181819
                    </Tooltip>
                  </h4>
                </div>
              </div>
              <a
                className=""
                target="_blank"
                rel="noreferrer"
                href="mailto:support@Chattafinance.com"
              >
                <div className="flex w-full  my-3 sm:w-[530px] gap-2 cursor-pointer">
                  <div className="min-w-[60px]">
                    <img
                      src={require("../Assets/Icons/refer-email.png")}
                      className="w-[40px] h-[40px] sm:w-[53px] sm:h-[53px] object-contain"
                      alt="help"
                    />
                  </div>

                  <div>
                    <h4 className="font-[600] text-[18px] sm:text-[20px] text-[#000]">
                      Email
                    </h4>
                    <h4 className="font-[400] sm:w-[479px] text-[13px] sm:text-[14px] text-[#000]">
                      Submit your complaints/request to
                      support@Chattafinance.com
                    </h4>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Help;
