import React, { useEffect } from "react";
import Header from "../Components/Bars/Header";
import Hero from "../Components/Main/Hero";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import WhyUs from "../Components/Main/WhyUs";
import Reviews from "../Components/Main/Reviews";
import FAQ from "../Components/Main/FAQ";
import ExchangeRate from "../Components/Main/ExchangeRate";
import { useFormik } from "formik";
import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";

const Home = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const form = useFormik({
    initialValues: {},
  });
  return (
    <div className="font-mons">
      <SEO
        title={"Chatta Finance Home-Page"}
        description={""}
        name={"Chatta Finance"}
        type={"article"}
      />
      <Header />
      <Hero />

      <ExchangeRate form={form} />

      <ScrollAnimation animateIn="fadeIn">
        <WhyUs />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn">
        <Reviews />
      </ScrollAnimation>
      <FAQ />
      <Footer />
    </div>
  );
};

export default Home;
