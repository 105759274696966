import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import SEO from "../Components/Utils/Seo";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { formHandler } from "../utils/formhandler";
import TextInput from "../Components/Inputs/TextInput";
import Button from "../Components/Inputs/Button";
import { createPaymentMethod } from "../store/actions/payments/createPaymentMethod";
import SelectField from "../Components/Inputs/SelectField";
import { paymentMethodSchema } from "../utils/validationSchemas";
// import axios from "axios";
import {
  CardElement,
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";

const AddCard = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [showSuccess, setShowSuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      cardNumber: "",
      accountType: {
        label: "Debit",
        value: "Debit",
      },
      expiry: "",
      cvv: "",
      pin: "",
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    // validationSchema: paymentMethodSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      const cardElement = elements.getElement("card");

      stripe.createToken(cardElement).then((payload) => {
        const token = payload?.token;

        const data = {
          accountType: token?.card?.funding,
          expiry: `${token?.card?.exp_month < 10 ? "0" : ""}${
            token?.card?.exp_month
          }/${token?.card?.exp_year?.toString()?.slice(-2)}`,
          paymentMethod: token?.card?.brand,
          cardNumber: token?.card?.last4,
          token: token?.id,
        };

        dispatch(
          createPaymentMethod({
            cb: () => {
              resetForm();
              setSubmitting(false);
              navigate("/settings/paymentmethod");
              // setShowSuccess(true);
            },
            payload: {
              ...data,
            },
            failed: () => {
              setSubmitting(false);
            },
          })
        );
      });

      // decodeCard()

      setSubmitting(false);
    },
  });

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Add Card"}
          description={""}
          name={"Chatta Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <div className="flex-1  max-w-[100vw] overflow-x-auto pt-4 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <h4
              onClick={() => {
                navigate(-1);
              }}
              className="cursor-pointer text-[21px] sm:text-[25px] font-[600] w-[646px] max-w-[100%] mt-6 mb-3 mx-auto text-[#000]"
            >
              Settings / Payment Method / Add a Card
            </h4>
            <div className="w-[646px] max-w-[100%] px-5 py-5 sm:px-8 mx-auto bg-[#FFFFFF]">
              <div className="w-full  flex flex-col">
                <CardElement
                  className="bg-[#FFF] mt-3 text-[26px] shadow-lg py-4 px-4 border border-[#999999] rounded-[8px]"

                  // postalCodeEnabled={false}
                  // placeholder={{ number: "1234 5678 1234 5678" }}
                  // onFocus={(focusedField) => {
                  //   console.log("focusField", focusedField);
                  // }}
                />
                {/* <div className="flex w-full flex-col my-6 items-center gap-4">
                  <TextInput
                    title={"Card Number"}
                    {...formHandler("cardNumber", form)}
                    type={"text"}
                    mainClasses={"!w-full sm:max-w-[371px]"}
                  />

                  <div className="w-full md:w-[371px]">
                    <SelectField
                      title="Account Type"
                      id="accountType"
                      form={form}
                      options={[
                        // {
                        //   label: "Credit",
                        //   value: "Credit",
                        // },
                        {
                          label: "Debit",
                          value: "Debit",
                        },
                      ]}
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </div>
                  <div className="flex items-start w-full sm:w-[371px] flex-col sm:flex-row gap-4 justify-between ">
                    <TextInput
                      title={"Expiry (mm/yy)"}
                      {...formHandler("expiry", form)}
                      type={"text"}
                      mainClasses={"!w-full sm:max-w-[176px]"}
                    />
                    <TextInput
                      title={"CVV"}
                      {...formHandler("cvv", form)}
                      type={"text"}
                      mainClasses={"!w-full sm:max-w-[176px]"}
                    />
                  </div>
                  <TextInput
                    title={"Pin"}
                    {...formHandler("pin", form)}
                    type={"text"}
                    mainClasses={"!w-full sm:max-w-[371px]"}
                  />
                </div> */}
                <img
                  src={require("../Assets/Images/paymentpartner.png")}
                  alt="camera"
                  className="w-[234.57px] h-[51px] my-5 mx-auto"
                />
                <Button
                  bg={"#192851"}
                  title={"Save this card"}
                  textStyles="font-[600]  text-[18px]"
                  mainClasses="h-[56px]  "
                  onClick={form.handleSubmit}
                  loading={form.isSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCard;
