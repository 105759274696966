import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { FaChevronRight } from "react-icons/fa6";

const Settings = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      fullName: "",
      bankName: "",
    },
    // validationSchema: signupSchema,
    onSubmit: (values, { setSubmitting }) => {
      const data = {
        ...values,
      };

      // dispatch(register({ navigate, data, setSubmitting }));
    },
  });

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Settings"}
          description={""}
          name={"Chatta Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <div className="flex-1  max-w-[100vw] overflow-x-auto pt-4 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <h4 className="text-[21px] sm:text-[25px] font-[600] w-[646px] max-w-[100%] mt-6 mb-3 mx-auto text-[#000]">
              Settings
            </h4>
            <div className="w-[646px] max-w-[100%] px-5 py-5 sm:px-8 mx-auto bg-[#FFFFFF]">
              <div
                onClick={() => {
                  navigate("/settings/editprofile");
                }}
                className="flex items-center py-5 sm:py-6 px-2 cursor-pointer border-b-[0.7px] border-b-[#707070]  justify-between"
              >
                <div className="flex items-center gap-6  sm:gap-8">
                  <div className="flex justify-center items-center w-[41px] h-[41px]">
                    <img
                      src={require("../Assets/Icons/profile.png")}
                      alt="currency"
                      className="w-[35px] h-[34px]"
                    />
                  </div>
                  <h4 className="text-[16px] sm:text-[18px] font-[500] text-[#000]">
                    Edit Profile
                  </h4>
                </div>
                <FaChevronRight color="#707070" size={"24px"} />
              </div>
              <div
                onClick={() => {
                  navigate("/settings/changepassword");
                }}
                className="flex items-center py-5 sm:py-6 px-2 cursor-pointer border-b-[0.7px] border-b-[#707070]  justify-between"
              >
                <div className="flex items-center gap-6  sm:gap-8">
                  <div className="flex justify-center items-center w-[41px] h-[41px]">
                    <img
                      onClick={() => {}}
                      src={require("../Assets/Icons/lock.png")}
                      alt="currency"
                      className="w-[41px] h-[41px]"
                    />
                  </div>
                  <h4 className="text-[16px] sm:text-[18px] font-[500] text-[#000]">
                    Change Password
                  </h4>
                </div>
                <FaChevronRight color="#707070" size={"24px"} />
              </div>
              <div
                onClick={() => {
                  navigate("/settings/paymentmethod");
                }}
                className="flex items-center py-5 sm:py-6 px-2 cursor-pointer border-b-[0.7px] border-b-[#707070]  justify-between"
              >
                <div className="flex items-center gap-6  sm:gap-8">
                  <div className="flex justify-center items-center w-[41px] h-[41px]">
                    <img
                      src={require("../Assets/Icons/payment.png")}
                      alt="currency"
                      className="w-[41px] h-[41px]"
                    />
                  </div>
                  <h4 className="text-[16px] sm:text-[18px] font-[500] text-[#000]">
                    Payment Method
                  </h4>
                </div>
                <FaChevronRight color="#707070" size={"24px"} />
              </div>
              <div
                onClick={() => {
                  navigate("/settings/kyc");
                }}
                className="flex items-center py-5 sm:py-6 px-2 cursor-pointer   justify-between"
              >
                <div className="flex items-center gap-6  sm:gap-8">
                  <div className="flex justify-center items-center w-[41px] h-[41px]">
                    <img
                      src={require("../Assets/Icons/kyc.png")}
                      alt="currency"
                      className="w-[41px] h-[41px]"
                    />
                  </div>
                  <h4 className="text-[16px] sm:text-[18px] font-[500] text-[#000]">
                    KYC Verification
                  </h4>
                </div>
                <FaChevronRight color="#707070" size={"24px"} />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Settings;
