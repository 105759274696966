import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import SideBar from "../Components/Bars/SideBar";
import Button from "../Components/Inputs/Button";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import TextInput from "../Components/Inputs/TextInput";
import { formHandler } from "../utils/formhandler";
import SuccessMessage from "../Components/Utils/SuccessMessage";
import { fundWalletSchema } from "../utils/validationSchemas";
import { fundWallet } from "../store/actions/wallet/fundWallet";
import { currencySymbolMap } from "../utils/data";

const FundWallet = () => {
  const [showSuccess, setShowSuccess] = useState(false);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const currency = queryParams.get("currency");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const { data: bankData } = useSelector((state) => state?.banks);
  const { token: cardToken } = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      amount: "",
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: fundWalletSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const data = {
        ...values,
        token: cardToken,
      };

      dispatch(
        fundWallet({
          cb: () => {
            resetForm();
            setSubmitting(false);
            setShowSuccess(values?.amount);
          },
          currency,
          payload: data,
          failed: () => {
            setSubmitting(false);
          },
        })
      );
    },
  });

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Add Beneficiaries"}
          description={""}
          name={"Chatta Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <SideBar />
          <div className="flex-1 mb-14  pt-4 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <h4 className="font-[600] text-lg mb-1">Add Money</h4>

            <div className="mt-5">
              <div className="flex w-[80%] md:w-[799px] mb-6 gap-2  flex-col  justify-between">
                <TextInput
                  title={"Amount"}
                  {...formHandler("amount", form)}
                  type={"number"}
                  placeholder={""}
                  mainClasses={"  md:w-[323px]"}
                />
                <h4
                  onClick={() => navigate("/settings/paymentmethod")}
                  className="cursor-pointer font-[500] underline text-[14px] mt-1 md:text-[15px] text-[#395AB5]  "
                >
                  Pay with debit/credit card
                </h4>
                <Button
                  bg={"#192851"}
                  title={"Continue"}
                  textStyles="font-[600] text-[18px]"
                  mainClasses="h-[56px]  mt-8"
                  // onClick={form.handleSubmit}
                  onClick={form.handleSubmit}
                  loading={form.isSubmitting}
                  // disabled={!form.dirty || !form.isValid || form.isSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {showSuccess ? (
        <SuccessMessage
          handleClose={() => {
            setShowSuccess(false);
            navigate("/");
          }}
          message={`Wallet funded with ${currencySymbolMap[currency]}${showSuccess} successfully`}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default FundWallet;
