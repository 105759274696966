import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { timeformatter } from "../utils/dateformatter";

import { getTransferRates } from "../store/actions/transactions/getTransferRates";
import Rate from "../Components/ListItems/Rate";

const ExchangeRate = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { pending, rates } = useSelector((state) => state.transactions);

  useEffect(() => {
    dispatch(getTransferRates());
  }, []);

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Exchange Rates"}
          description={""}
          name={"Chatta Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <div className="flex-1  max-w-[100vw] overflow-x-auto pt-4 pb-6 sm:pb-9 px-5 md:px-9 bg-[#F1F1F1]">
            <h4 className="text-[21px] sm:text-[25px] font-[600] w-[646px] max-w-[100%] mt-6 mb-3 mx-auto text-[#000]">
              Exchange Rates
            </h4>
            <h4 className="text-[14px] sm:text-[16px] font-[600] w-[646px] max-w-[100%] mt-1 mx-auto text-[#000]">
              Last updated:
            </h4>
            <h4 className="text-[14px] sm:text-[16px] font-[600] w-[646px] max-w-[100%]  mx-auto text-[#000]">
              {timeformatter(rates[0]?.updatedAt, "formal", true)}
            </h4>
            <div className="w-[690px] rounded-[20px] mt-2 sm:mt-4 max-w-[100%] px-5 py-5 sm:px-8 mx-auto bg-[#FFFFFF]">
              <h4 className="text-[14px] sm:text-[16px] font-[600] w-full sm:w-[530px] max-w-[100%] mt-1 mx-auto text-[#000]">
                Today’s Rate
              </h4>

              {rates
                ?.filter((curr) => curr?.currency !== "USD")
                ?.map((rate) => {
                  return <Rate rate={rate} />;
                })}
              <div className="flex items-center justify-center mt-4 py-2 gap-3">
                <div className=" flex h-[30px] cursor-pointer w-[78px] items-center justify-center  border-[0.5px] rounded-[6px]">
                  <h4 className="text-[15px] sm:text-[15px] font-[400] text-[#9F9D9D]">
                    Previous
                  </h4>
                </div>

                <div
                  className={`flex h-[30px] w-[78px]  items-center justify-center ${
                    rates?.length > 10
                      ? "border-[#000] cursor-pointer"
                      : "cursor-not-allowed border-[#9F9D9D]"
                  } border-[0.5px] rounded-[6px]`}
                >
                  <h4
                    className={`text-[15px] sm:text-[15px] font-[400] ${
                      rates?.length > 10
                        ? "text-[#000] cursor-pointer"
                        : "cursor-not-allowed text-[#9F9D9D]"
                    } ]`}
                  >
                    Next
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ExchangeRate;
