import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import SEO from "../Components/Utils/Seo";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Button from "../Components/Inputs/Button";
import { getPaymentMethods } from "../store/actions/payments/getPaymentMethods";
import PaymentCards from "../Components/ListItems/PaymentCards";

const PaymentMethod = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const form = useFormik({
    initialValues: {
      fullName: "",
      bankName: "",
    },
    // validationSchema: signupSchema,
    onSubmit: (values, { setSubmitting }) => {
      const data = {
        ...values,
      };

      // dispatch(register({ navigate, data, setSubmitting }));
    },
  });

  const { pending, paymentMethods } = useSelector((state) => state?.user);
  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  const [selected, setSelected] = useState(0);

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Payment Method"}
          description={""}
          name={"Chatta Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <div className="flex-1  max-w-[100vw] overflow-x-auto pt-4 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <h4
              onClick={() => {
                navigate(-1);
              }}
              className="cursor-pointer text-[21px] sm:text-[25px] font-[600] w-[646px] max-w-[100%] mt-6 mb-3 mx-auto text-[#000]"
            >
              Settings / Payment Method
            </h4>
            <div className="w-[646px] max-w-[100%] overflow-y-auto justify-between flex flex-col h-[55vh] sm:h-[65vh] mb-5 px-5 py-5 sm:px-8 mx-auto bg-[#FFFFFF]">
              <div>
                {paymentMethods?.length > 0 ? (
                  paymentMethods?.map((opt, index) => {
                    return (
                      <PaymentCards
                        index={index}
                        selected={selected}
                        setSelected={setSelected}
                        opt={opt}
                        key={index}
                      />
                    );
                  })
                ) : (
                  <div className="w-full h-full items-center justify-center gap-1 sm:gap-3 flex flex-col">
                    <img
                      src={require("../Assets/Icons/credit-card.png")}
                      alt="credit-card"
                      className="w-[145px] h-[145px] "
                    />
                    <h4 className="text-[16px] sm:text-[18px] mb-2 font-[600] text-[#000]">
                      Add Debit/Credit Card
                    </h4>
                    <h4 className="text-[12px] w-[250px] text-center sm:text-[14px] font-[400] text-[#616161]">
                      For faster payment process, securely add your debit/credit
                      card to your account.
                    </h4>
                  </div>
                )}
              </div>
              <div className="mx-auto  justify-self-end">
                <Button
                  bg={"#192851"}
                  title={"Add a card"}
                  textStyles="font-[600]  text-[18px]"
                  mainClasses="h-[56px]  "
                  onClick={() => {
                    navigate("/settings/paymentmethod/addcard");
                    // setShowSuccess(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
