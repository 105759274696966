// import { logHelper } from "./logHelper";

import { toast } from "react-toastify";

export const proccessErrorMessage = async (e, category = "Error") => {
  // console.log("here", e);
  // logHelper(e?.message, 'Error From Proceess Error1');
  // logHelper(e?.response?.data, 'Error From Proceess Error2');
  // logHelper(e?.response?.data?.data, 'Error From Proceess Error3');
  // logHelper(
  //   e?.response?.data?.data?.data?.status,
  //   'Error From Proceess Error4',
  // );
  // logHelper(
  //   e?.response?.data?.data?.data?.next_action,
  //   'next_actionError From Proceess Error',
  // );

  const message =
    e?.response?.data?.message || e?.message || e || "An error has occured!";
  toast.error(message);
};
