import React from "react";

import { Carousel } from "react-responsive-carousel";

const Hero = () => {
  return (
    <div className="bg-[#EDF2FF] pt-24">
      <Carousel
        className="carousel"
        autoPlay
        emulateTouch
        swipeable
        showStatus={false}
        infiniteLoop
        showArrows={false}
      >
        <div className="container outline-none pl-3 pb-20 mx-auto lg:h-[628px] flex flex-wrap flex-col lg:flex-row lg:justify-between  items-center lg:items-end">
          <div className="flex flex-col w-full lg:w-2/6 justify-center items-start text-center lg:text-left pb-20">
            <h1 className="mb-2 outline-none text-[#4F3970] w-[90%] md:w-[70%] lg:w-full mx-auto text-[35px] my-6 md:text-[55px] lg:text-[55px] font-[700] leading-none md:leading-[67.05px] px-8 sm:px-0">
              Cross border payments made easy
            </h1>

            <p className=" text-[#4F3970] font-[300] lg:text-xl sm:leading-[34px] tracking-[-1%] my-5 lg:my-0  mx-auto lg:mx-0 w-[60%] lg:w-[90%]">
              Send money securely, effortlessly, and instantly with our trusted
              money transfer service.
            </p>
            <div className="flex items-center  mx-auto lg:ml-[-8px] mt-3">
              <a href="#">
                <img
                  className="w-[154.74px] h-[60px] cursor-pointer "
                  src={require("../../Assets/Images/app-store.png")}
                />
              </a>
              <a href="#">
                <img
                  className="w-[155px] h-[66px] px-2  cursor-pointer"
                  src={require("../../Assets/Images/platy-store.png")}
                />
              </a>
            </div>
          </div>

          <div className="w-full lg:w-3/5  py-6 text-center relative">
            <img
              className="w-full lg:w-4/5 z-50 max-w-[400px] max-h-[400px] lg:max-w-none lg:max-h-none"
              src={require("../../Assets/Images/hero.png")}
            />
          </div>
        </div>
        <div className="container pl-3 pb-20 mx-auto lg:h-[628px] flex flex-wrap flex-col lg:flex-row justify-between  items-center lg:items-end">
          <div className="flex flex-col w-full lg:w-[35%] justify-center items-start text-center lg:text-left  ">
            <h1 className="mb-2 text-[#4F3970] w-[90%] md:w-[70%] lg:w-full mx-auto text-[35px] my-6 md:text-[55px] lg:text-[55px] font-[700] leading-none md:leading-[67.05px] px-8 sm:px-0">
              Your global connection just got easier
            </h1>

            <p className=" text-[#4F3970] font-[300] lg:text-xl sm:leading-[34px] tracking-[-1%] my-5 lg:my-0  mx-auto lg:mx-0 w-[60%] lg:w-[90%]">
              Send money in minutes internationally
            </p>
            <div className="flex items-center  mx-auto lg:ml-[-8px] mt-3">
              <a href="#">
                <img
                  className="w-[154.74px] h-[60px] cursor-pointer "
                  src={require("../../Assets/Images/app-store.png")}
                />
              </a>
              <a href="#">
                <img
                  className="w-[155px] h-[66px] px-2 cursor-pointer"
                  src={require("../../Assets/Images/platy-store.png")}
                />
              </a>
            </div>
          </div>

          <div className="w-full lg:w-3/5  pt-6 text-center flex justify-center lg:justify-end relative">
            <img
              className="w-full lg:w-4/5 z-50 max-w-[400px] max-h-[400px] lg:max-w-[468px] lg:max-h-[468px] "
              src={require("../../Assets/Images/hero2.png")}
            />
          </div>
        </div>
        <div className="container pl-3 pb-20 mx-auto lg:h-[628px] flex flex-wrap flex-col lg:flex-row lg:justify-between  items-center lg:items-end ">
          <div className="flex flex-col w-full lg:w-2/6 justify-center items-start text-center lg:text-left pb-0">
            <h1 className="mb-2 text-[#4F3970] w-[90%] md:w-[70%] lg:w-full mx-auto text-[35px] my-6 md:text-[55px] lg:text-[55px] font-[700] leading-none md:leading-[67.05px] px-8 sm:px-0">
              Your world, your money, simplified.
            </h1>

            <p className=" text-[#4F3970] font-[300] lg:text-xl sm:leading-[34px] tracking-[-1%] my-5 lg:my-0  mx-auto lg:mx-0 w-[60%] lg:w-[90%]">
              Make transfers to other countries in minutes
            </p>
            <div className="flex items-center  mx-auto lg:ml-[-8px] mt-3">
              <a href="#">
                <img
                  className="w-[154.74px] h-[60px] px-2 cursor-pointer "
                  src={require("../../Assets/Images/app-store.png")}
                />
              </a>
              <a href="#">
                <img
                  className="w-[155px] h-[66px] cursor-pointer"
                  src={require("../../Assets/Images/platy-store.png")}
                />
              </a>
            </div>
          </div>

          <div className="w-full lg:w-3/5  py-6 text-center relative">
            <img
              className="w-full lg:w-4/5 z-50 max-w-[400px] max-h-[400px] lg:max-w-[690.74px] lg:max-h-[467.12px] "
              src={require("../../Assets/Images/hero3.png")}
            />
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Hero;
