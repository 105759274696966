import React, { useEffect, useState } from "react";
import Header from "../Components/Bars/Header";
import SEO from "../Components/Utils/Seo";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { formHandler } from "../utils/formhandler";
import TextInput from "../Components/Inputs/TextInput";
import Button from "../Components/Inputs/Button";
import FilePicker from "../Components/Inputs/FilePicker";
import { boiSchema } from "../utils/validationSchemas";
import { completeKYC } from "../store/actions/profile/completeKYC";
import Selfie from "../Components/Inputs/Selfie";

const BiometricPassport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {
      file: null,
      homeAddress: "",
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: boiSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const formData = new FormData();
      formData.append("homeAddress", values.homeAddress);

      formData.append("type", "BIOMETRIC RESIDENTIAL PASSPORT");
      formData.append("selfie", values.selfie);
      formData.append("file", values.file);

      dispatch(
        completeKYC({
          cb: () => {
            // resetForm();
            setSubmitting(false);
            // navigate("/payments");
            // setShowSuccess(true);
          },
          payload: formData,
          failed: () => {
            setSubmitting(false);
          },
        })
      );
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="font-mons">
        <SEO
          title={"Complete KYC"}
          description={""}
          name={"Chatta Finance"}
          type={"article"}
        />
        <Header />
        <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
          <div className="flex-1  max-w-[100vw] overflow-x-auto pt-4 pb-6 px-5 md:px-9 bg-[#F1F1F1]">
            <h4
              onClick={() => {
                navigate(-1);
              }}
              className="cursor-pointer text-[21px] sm:text-[25px] font-[600] w-[646px] max-w-[100%] mt-6 mb-3 mx-auto text-[#000]"
            >
              Settings / KYC Verification / Biometric Residential Passport
            </h4>
            <div className="w-[646px] max-w-[100%] px-5 py-5 sm:px-8 mx-auto bg-[#FFFFFF]">
              <div className="w-full items-center flex flex-col">
                <div className="flex w-full flex-col my-6 items-center gap-4">
                  <div>
                    <h4 className="text-[#707070] font-[400] text-[14px] mb-1 leading-[21.94px] w-full sm:w-[371px]  ">
                      Upload document:
                    </h4>
                    <FilePicker
                      id="file"
                      {...formHandler("file", form)}
                      // type={"text"}
                      form={form}
                    />
                  </div>

                  <TextInput
                    title={"Home Address:"}
                    {...formHandler("homeAddress", form)}
                    type={"text"}
                    mainClasses={"!w-full sm:max-w-[371px]"}
                  />

                  <div>
                    <h4 className="text-[#707070] font-[400] text-[14px] leading-[21.94px] w-full sm:w-[371px]  ">
                      Face Verification
                    </h4>
                    <h4 className="text-[12px] sm:text-[13px] font-[400] mb-1 text-[#707070]">
                      Take a selfie picture of your face in a well lit
                      environment
                    </h4>

                    <Selfie
                      id="selfie"
                      {...formHandler("selfie", form)}
                      form={form}
                    />
                  </div>
                </div>

                <Button
                  bg={"#192851"}
                  title={"Done"}
                  textStyles="font-[600]  text-[18px]"
                  mainClasses="h-[45px]  "
                  onClick={form.handleSubmit}
                  loading={form.isSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BiometricPassport;
