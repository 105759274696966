import React, { useEffect } from "react";
import Header from "../Components/Bars/Header";
import Footer from "../Components/Main/Footer";
import SEO from "../Components/Utils/Seo";
import TransferTop from "../Components/Main/TransferTop";
import TransferBottom from "../Components/Main/TransferBottom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { transferSchema } from "../utils/validationSchemas";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "../Components/Bars/SideBar";

const Transfer = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const form = useFormik({
    initialValues: {
      recipient: "",
      accountNumber: "",
      bankName: null,
      amount: "1",
      swiftCode: "",
      currency: "GBP",
      recipientCurrency: "NGN",
      reference: "",
      beneficiary: "",
      recieverAmount: "1895.234",
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: transferSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      navigate("/transfer/confirmation", { state: values });
    },
  });

  useEffect(() => {
    if (state) {
      form.setValues(state);
    }
  }, [state]);

  return (
    <div className="font-mons">
      <SEO
        title={"Transfer"}
        description={""}
        name={"Chatta Finance"}
        type={"article"}
      />
      <Header />
      <div className="lg:hidden">
        <SideBar />
      </div>
      <TransferTop oldState={state} form={form} />
      <TransferBottom form={form} />
      <Footer />
    </div>
  );
};

export default Transfer;
